import { createSlice } from "@reduxjs/toolkit"

const tokenSlice = createSlice({
    name: "token",
    initialState: {token: ""},
    reducers:{
        addToken: (state, action) =>{
            return { ...state, token: action.payload }
        },
        removeToken: (state) => {
            return {...state, token: ""}
        }
    }
})

export const {addToken, removeToken} = tokenSlice.actions
export default tokenSlice.reducer