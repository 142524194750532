import { Box, Button, Dialog, IconButton, Typography, useTheme } from "@mui/material";
import { useContext, useState } from "react";
import InputBase from "@mui/material/InputBase";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import SearchIcon from "@mui/icons-material/Search";
import { useDispatch, useSelector } from "react-redux";
import { onDark, offDark } from "../App/features/darkMode";
import { useRequestComparisionMutation, useRequestSurveyMutation } from "../Services/ApiServices";
import FetchLoading from "./Messages/FetchLoading";

const Topbar = () => {
  const theme = useTheme();
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const [message, setMessage] = useState({message:"", type:"primary"})
  const [sendCompRequest, { isLoading: compLoading, error: compErr, isSuccess: compSuc }] = useRequestComparisionMutation();
  const [sendSurvRequest, { isLoading: survLoading, error: survErr, isSuccess: survSuc }] = useRequestSurveyMutation()
  
  const dark = useSelector(state => state.darkMode.dark)
  const handleDarkMode = () =>{
    if(dark) {
      dispatch(offDark())
    }
    else {
      dispatch(onDark())
    }
  }
  const sendrequestSurvey = async () =>{
    setOpen((prev) =>{ return true})
    setMessage({message:"", type:"primary"})
    await sendSurvRequest().then((response) =>{
      if(survSuc){
        setMessage((prev) =>{ return {message: response.data.data.message}})
      }
      else{
        setMessage((prev) =>{ return {message: response.data.data.message}})
      }
    })
  }
  const sendrequestComp = async () =>{
    setOpen((prev) =>{ return true})
    setMessage({message:"", type:"primary"})
    await sendCompRequest().then((response) =>{
      if(compSuc){
        setMessage((prev) =>{ return {message: response.data.data.message}})
      }
      else{
        setMessage((prev) =>{ return {message: response.data.data.message}})
      }
    })
  }
  
  const handleClosing = () =>{
    setOpen(false)
    setMessage({message:"", type:"primary"})
  }

  return (
    <div style={{ display:"flex",width:"100%",padding:"2%", justifyContent:"space-between",backgroundColor: "default"}}>
      <Dialog
        open={open}
        aria-labelledby="responsive-dialog-title">
        <Box
        display="flex"
        backgroundColor={theme.palette.background.main}
        borderRadius="3px"
        sx={{ width:"400px", height:"150px",display:"flex", justifyContent:"center" }}>
        <>
        {(compLoading || survLoading) ? <FetchLoading/> : <div sx={{ height:"150px"}}>
        <Typography variant="h5" sx = {{ color:message.type, marginTop:"40px" }}>{message.message}</Typography>
        <Button variant="outlined" sx={{ width:"100%" }} onClick={handleClosing} >Cancel</Button>
        </div>
        }
        </>
      </Box>
      </Dialog>
      <Box
        display="flex"
        backgroundColor={theme.palette.background.main}
        borderRadius="3px"
      >
      </Box>
      <Box display="flex">
        
        <Button style={{ margin:"2%"}} color="warning" onClick={()=>{sendrequestComp()}} variant="outlined">Comparison</Button>
        <Button style={{ margin:"2%"}} color="warning" onClick={()=>{sendrequestSurvey()}} variant="outlined">Survey</Button>
        
        <IconButton onClick={theme.palette.background.main}>
          {theme.palette.mode === "dark" ? (
            <LightModeOutlinedIcon color="primary" onClick={() => {handleDarkMode()}} />
          ) : (
            <DarkModeOutlinedIcon color="primary"  onClick={() => {handleDarkMode()}}  />
          )}
        </IconButton>
        <IconButton color="primary">
          <NotificationsOutlinedIcon color="primary" />
        </IconButton>
        <IconButton color="primary">
          <PersonOutlinedIcon color="primary" />
        </IconButton>
      </Box>
    </div>
  );
};

export default Topbar;