import MainSideBar from "../../../Components/SideBar";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useGetBranchesQuery, usePostBranchComparisonMutation } from "../../../Services/ApiServices";
import { Button, FormControlLabel, Checkbox, FormGroup, Box, Grid, Typography } from "@mui/material";
import FetchLoading from "../../../Components/Messages/FetchLoading";
import { useNavigate } from "react-router-dom";

export default function CompareBranches() {
  const dispatch = useDispatch();
  const { data, error } = useGetBranchesQuery();
  const [branchList, setBranchList] = useState([]);
  const [selectedBranches, setSelectedBranches] = useState([]); // Track selected branches
  const [err, setErr] = useState({ error: false, message: "" });
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const [postBranchComparison, { isLoading: isComparing, error: compareError }] = usePostBranchComparisonMutation();

  useEffect(() => {
    if (data && data.data && data.data.branches) {
      setBranchList(data.data.branches);
      setLoading(false);
    } else if (error) {
      setErr({ error: true, message: "Failed to load branches" });
      setLoading(false);
    }
  }, [data, error]);

  // Handle checkbox toggle
  const handleBranchToggle = (branchId) => {
    setSelectedBranches((prevSelected) => {
      if (prevSelected.includes(branchId)) {
        return prevSelected.filter((id) => id !== branchId);
      } else {
        return [...prevSelected, branchId];
      }
    });
  };

  const handleCompare = async () => {
    if (selectedBranches.length > 1) {
      try {
        console.log(selectedBranches);
        // Call the comparison API and unwrap the response
        const response = await postBranchComparison({ branchIds: selectedBranches }).unwrap();
           console.log("responce is", response)
        // Pass the response data when navigating to the next page
        navigate("/ViewBranchComparison", { state: { comparisonData: response } });
        console.log("responce is is is", response.data)
      } catch (error) {
        console.error("Error comparing branches:", error);
        setErr({ error: true, message: "Failed to compare branches" });
      }
    } else {
      setErr({ error: true, message: "Please select at least two branches" });
    }
  };
  

  return (
    <MainSideBar>
      <Box sx={{ padding: "2rem", maxWidth: "1200px", margin: "auto" }}>
        <Typography variant="h4" gutterBottom>
          Compare Branches
        </Typography>
        {loading ? (
          <FetchLoading />
        ) : err.error ? (
          <Typography color="error">Error: {err.message}</Typography>
        ) : (
          <>
            <Box sx={{ marginBottom: "2rem" ,backgroundColor: "white", padding:"2rem" }}>
              <Typography variant="h6" gutterBottom>
                Select Branches to Compare
              </Typography>

              <Grid container spacing={3}>
                {branchList.map((branch) => (
                  <Grid item xs={12} sm={6} md={4} key={branch.id}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedBranches.includes(branch.id)}
                          onChange={() => handleBranchToggle(branch.id)}
                          color="primary"
                        />
                      }
                      label={<Typography variant="subtitle1" fontWeight="bold">{branch.name}</Typography>}
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>

            <Button
              variant="contained"
              color="primary"
              onClick={handleCompare}
              disabled={selectedBranches.length < 2 || isComparing}
              sx={{ padding: "0.75rem 2rem", fontSize: "1rem", marginTop: "1rem" }}
            >
              {isComparing ? "Comparing..." : "Compare Branches"}
            </Button>

            {compareError && (
              <Typography color="error" sx={{ marginTop: "1rem" }}>
                Error: Failed to compare branches.
              </Typography>
            )}

            {err.error && (
              <Typography color="error" sx={{ marginTop: "1rem" }}>
                {err.message}
              </Typography>
            )}
          </>
        )}
      </Box>
    </MainSideBar>
  );
}
