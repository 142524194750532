import MainSideBar from "../../../Components/SideBar"
import { useGetRegionsQuery, usePostRegionComparisonMutation } from "../../../Services/ApiServices";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Button, FormControlLabel, Checkbox, FormGroup, Box, Grid, Typography, Card, CardContent } from "@mui/material";
import FetchLoading from "../../../Components/Messages/FetchLoading";
import { useNavigate } from "react-router-dom";

export default function CompareRegion() {
    const dispatch = useDispatch();
    const { data, refetch, isLoading, error } = useGetRegionsQuery();
    const [branchList, setBranchList] = useState([]);
    const [selectedBranches, setSelectedBranches] = useState([]); // Track selected branches
    const [err, setErr] = useState({ error: false, message: "" });
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    console.log("the sent data ", data)

    const [postBranchComparison, { isLoading: isComparing, error: compareError }] = usePostRegionComparisonMutation();

    useEffect(() => {
      if (data && data.data && data.data.regions) {
        setBranchList(data.data.regions);
        setLoading(false);
      } else if (error) {
        setErr({ error: true, message: "Failed to load regions" });
        setLoading(false);
      }
    }, [data, error]);

    // Handle checkbox toggle
    const handleBranchToggle = (regionId) => {
      setSelectedBranches((prevSelected) => {
        if (prevSelected.includes(regionId)) {
          return prevSelected.filter((id) => id !== regionId);
        } else {
          return [...prevSelected, regionId ];
        }
      });
    };

    const handleCompare = async () => {
      if (selectedBranches.length > 1) {
        try {
          console.log("Selected branches: ", selectedBranches);
          
          // Call the comparison API with branchIds directly (no extra array)
          const response = await postBranchComparison({ regionIds: selectedBranches }).unwrap();
          
          console.log("Data sent:", { branchIds: selectedBranches });
          console.log("Response received:", response);
          
          // Navigate with comparison data
          navigate("/ViewRegionComparison", { state: { comparisonData: response } });
        } catch (error) {
          console.error("Error comparing regions:", error);
          setErr({ error: true, message: "Failed to compare regions" });
        }
      } else {
        setErr({ error: true, message: "Please select at least two regions" });
      }
    };
    
    

    return (
        <MainSideBar>
          <Box sx={{ padding: "2rem", maxWidth: "1200px", margin: "auto" }}>
            <Typography variant="h4" gutterBottom>
              Compare Regions
            </Typography>
            {loading ? (
              <FetchLoading />
            ) : err.error ? (
              <Typography color="error">Error: {err.message}</Typography>
            ) : (
              <>
                <Box sx={{ marginBottom: "2rem", backgroundColor: "white", padding: "2rem" }}>
                  <Typography variant="h6" gutterBottom>
                    Select regions to Compare
                  </Typography>
      
                  <Card>
                    <CardContent>
                      <FormGroup>
                        <Grid container spacing={3}>
                          {branchList.map((region) => (
                            <Grid
                              item
                              xs={12}
                              sm={6}
                              md={4}
                              key={region.id}
                            >
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={selectedBranches.includes(region.id)}
                                    onChange={() => handleBranchToggle(region.id)}
                                    color="primary"
                                  />
                                }
                                label={
                                  <Typography variant="subtitle1" fontWeight="bold">
                                    {region.name}
                                  </Typography>
                                }
                              />
                            </Grid>
                          ))}
                        </Grid>
                      </FormGroup>
                    </CardContent>
                  </Card>
      
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleCompare}
                    disabled={selectedBranches.length < 2 || isComparing}
                    sx={{ padding: "0.75rem 2rem", fontSize: "1rem", marginTop: "1rem" }}
                  >
                    {isComparing ? "Comparing..." : "Compare regions"}
                  </Button>
      
                  {compareError && (
                    <Typography color="error" sx={{ marginTop: "1rem" }}>
                      Error: Failed to compare regions.
                    </Typography>
                  )}
      
                  {err.error && (
                    <Typography color="error" sx={{ marginTop: "1rem" }}>
                      {err.message}
                    </Typography>
                  )}
                </Box>
              </>
            )}
          </Box>
        </MainSideBar>
      );
}