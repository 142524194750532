import * as React from 'react';
import { useState } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
import {TextField, Button,Typography,Select, MenuItem, InputLabel, FormControl } from '@mui/material/';
import MainFormEditor from './EditorComponents/FormEditor';
import Table from './EditorComponents/FormTable';
import { useDispatch, useSelector } from 'react-redux';
import { ArrowBack } from '@mui/icons-material';
import { apiSlice, useGetFormsQuery } from '../../../Services/ApiServices';
import { addToForm, removeForm } from '../../../App/features/formSlice';
import { addToLcForm } from '../../../App/features/localFormSlice';
import FetchLoading from '../../../Components/Messages/FetchLoading';
import MainSideBar from '../../../Components/SideBar';
import { useParams } from 'react-router-dom';


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export default function EditForm () {
  const dispatch = useDispatch()
  const { formsId } = useParams();
  const { data, refetch, isLoading, isSuccess, isError, error } = useGetFormsQuery(formsId);
  const form = useSelector(state=>state.form)
  const lcForm = useSelector(state=>state.lcForm)
  const [err, setErr] = useState({error: false, message: ""})
  const [loading, setLoading] = useState(true)
  const [disableBtn, setDisable] = useState(false)
  React.useEffect(()=>{
    updater()
        }, [])
        
      const updater = async () =>{
      dispatch(apiSlice.util.resetApiState())
      const res = await refetch({ forceRefetch: true }).then((response)=>{
      if(response.isError) {
        setLoading((prev) =>{ return false})
        setErr((prev) => { return {...prev, error: true, message: response}})
      }
      if(response.isSuccess) {
        dispatch(addToForm(response.data.data.reviewAttributes))
        dispatch(addToLcForm(response.data.data.reviewAttributes))
        setLoading((prev) =>{ return false})
      }
    })
    
  }
  
  // if(!lcForm.length) {
  //   dispatch(addToLcForm((form)))
  // }
  const [tempForm, setTempForm] = useState([...form])
  const [dataEdit, setDataEdit] = useState({})

  const paperStyle={padding :20, margin:"0 auto"}
  

  return (
    <MainSideBar>

    <>
    {
      loading ? <FetchLoading/> :
    <>
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid md={7}>
          <div>
            <Paper style={paperStyle} elevation={5}>
              <Grid container gap={3} alignContent="center">
                <Button variant='outlined' onClick={()=>{ window.history.back(); }} ><ArrowBack/> Go Back</Button>
                <Grid xs={12}>
                  <h2>Form Attributes</h2>
                  {
                   lcForm && <Table data={lcForm} updater = {updater} setDisable={setDisable}  setTempForm = {setTempForm} setDataEdit={setDataEdit} />
                  }
                </Grid>
              </Grid>
            </Paper>
          </div>
        </Grid>
        <Grid md={5}>
          <div>
            <MainFormEditor data = {dataEdit} setDataEdit={setDataEdit} disableBtn = {disableBtn}  setDisable={setDisable} setTempForm = {setTempForm} />
          </div>
        </Grid>
      </Grid>
    </Box>
    </>
    }
    </>
    </MainSideBar>
  );
}
