import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactApexChart from "react-apexcharts";
import { Typography, Box, Paper, Tabs, Tab } from "@mui/material";
import MainSideBar from "../../../Components/SideBar";
import ReactMarkdown from 'react-markdown';

const CombinedColumnChart = () => {
  const location = useLocation();
  const [selectedAspect, setSelectedAspect] = useState("Food");
  const [sentiments, setSentiments] = useState(null);

  useEffect(() => {
    // Retrieve comparisonData from location state
    const comparisonData = location.state?.comparisonData;
    
    if (comparisonData) {
      // Extract and set sentiments
      setSentiments(comparisonData.data?.sentiments || null);
    }
  }, [location.state?.comparisonData]);

  // Check if sentiments or selectedAspect are undefined/null
  if (!sentiments || !sentiments[selectedAspect]) {
    return (
      <MainSideBar>
        <Box display="flex" flexDirection="column" padding={2}>
          <Typography variant="h6" gutterBottom>
            No data available
          </Typography>
        </Box>
      </MainSideBar>
    );
  }

  const regions = Object.keys(sentiments[selectedAspect]);

  // Aggregate sentiment values for all regions
  const aggregateSentimentValues = (aspect) => {
    const sentimentAggregation = {};

    regions.forEach((region) => {
      const regionSentiments = sentiments[aspect][region].sentiment_values;

      Object.keys(regionSentiments).forEach((sentimentName) => {
        if (!sentimentAggregation[sentimentName]) {
          sentimentAggregation[sentimentName] = 0;
        }
        sentimentAggregation[sentimentName] += regionSentiments[sentimentName];
      });
    });

    return Object.keys(sentimentAggregation).map((name) => ({
      name,
      data: regions.map((region) => sentiments[aspect][region].sentiment_values[name] || 0),
    }));
  };

  const series = aggregateSentimentValues(selectedAspect);

  const options = {
    chart: {
      type: "bar",
      height: 450,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "40%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: regions,
    },
    yaxis: {
      title: {
        text: "Total Count",
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: (val, { seriesIndex, dataPointIndex }) => {
          const sentiment = series[seriesIndex] ? series[seriesIndex].name : "";
          const region = regions[dataPointIndex];
          const sentimentData = sentiments[selectedAspect][region].sentiment_values[sentiment];
          return `${val} (${sentimentData !== undefined ? sentimentData : "N/A"})`;
        },
      },
    },
  };

  // Aggregate summaries and comparisons for all regions
  const aggregateSummary = regions.map((region) => {
    return sentiments[selectedAspect][region].summary;
  }).join(" ");

  const aggregateComparison = regions.map((region) => {
    return sentiments[selectedAspect][region].comparison;
  }).join(" ");

  return (
    <MainSideBar>
      <Box display="flex" flexDirection="column" padding={2}>
        {/* Aspect Tabs */}
        <Tabs
          value={selectedAspect}
          onChange={(event, newValue) => setSelectedAspect(newValue)}
          variant="fullWidth"
          aria-label="aspect tabs"
          style={{ marginBottom: 16 }}
        >
          {Object.keys(sentiments).map((aspect) => (
            <Tab key={aspect} label={aspect} value={aspect} />
          ))}
        </Tabs>

        {/* Summary */}
        <Box marginBottom={2}>
          <Typography variant="h2" gutterBottom>
            Summary for {selectedAspect}
          </Typography>
          <Paper elevation={3} style={{ padding: 16, marginBottom: 16 }}>
            <Typography variant="body1" gutterBottom>
              {/* Use ReactMarkdown for displaying the summary in markdown format */}
              <ReactMarkdown>
                {aggregateSummary || "No data available"}
              </ReactMarkdown>
            </Typography>
          </Paper>
        </Box>

        {/* Chart */}
        <Box marginBottom={2}>
          <Typography variant="h2" gutterBottom>
            Combined Column Chart
          </Typography>
          <Paper elevation={3} style={{ padding: 16, marginBottom: 16 }}>
            <ReactApexChart
              options={options}
              series={series}
              type="bar"
              height={450}
              width={1200}
            />
          </Paper>
        </Box>

        {/* Comparison */}
        <Box marginBottom={2}>
          <Typography variant="h2" gutterBottom>
            Comparison for {selectedAspect}
          </Typography>
          <Paper elevation={3} style={{ padding: 16 }}>
            <Typography variant="body1" gutterBottom>
              {/* Use ReactMarkdown for displaying the comparison in markdown format */}
              <ReactMarkdown>
                {aggregateComparison || "No data available"}
              </ReactMarkdown>
            </Typography>
          </Paper>
        </Box>
      </Box>
    </MainSideBar>
  );
};

export default CombinedColumnChart;
