import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import React, { useState } from 'react'
import { useDeleteAdminMutation } from '../../../Services/ApiServices'
import FetchLoading from '../../../Components/Messages/FetchLoading'
import { MessageGenerator, type } from '../../../Components/Messages/MesageGenerator'

const DeleteAdmin = ({open, name, id, handleClose, updater}) => {
  const [err, setErr] = useState({error: false, message: ""})
  const [msg, setMsg] = useState({suc: false, message: ""})
  const [mutate, { isLoading, isSid,uccess,isError, error }] = useDeleteAdminMutation()
  const handleDelete = async () =>{
    const resp = mutate(id).then((response) =>{
      if(response.error) {
        setErr((prev) =>{ return {...prev, error: true, message: response.error.data.data.message}})
      }
      if(response.data){
        setMsg((prev) => { return { ...prev, suc: true, message: response.data.data.message}})
        updater()
        handleClosing()
      }
    })
  }
  const handleClosing = () =>{
    setErr((prev) => {return {...prev, error: false, message:""}})
    setMsg((prev) => {return {suc: false, message: "" }})
    handleClose((prev) => {return false})
  }
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClosing}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle color="error" id="responsive-dialog-title">
          {"Delete Admin"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText color="main">
            Are you sure do you want to delete the user {name}?
          </DialogContentText>
        </DialogContent>
        <DialogContent style={{ width: "100%" }}>
          <div style={{ width:"100%", display:"flex", justifyContent:"center" }}>
            {isLoading && <CircularProgress style={{ margin:"auto 0" }} />}
             {(msg.suc && !isLoading) && <MessageGenerator type={type.suc} message={msg.message} />}
             {(err.error && !isLoading) && <MessageGenerator type={type.err} message={err.message} />}
          </div>
        </DialogContent>
        <DialogActions>
          <Button color="error"  variant='contained' autoFocus onClick={handleDelete}>
            Delete
          </Button>
          <Button onClick={handleClosing} autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default DeleteAdmin
