// import { useEffect, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { apiSlice, useGetNexusAnaysisQuery, useGetGoogleAnaysisQuery, useGetOpenAIAnaysisQuery } from "../Services/ApiServices";
// import { addHotel } from "../App/features/hotelDetail";
// import MainSideBar from "./SideBar";
// import FetchLoading from "./Messages/FetchLoading";
// import HotelInformation from "./HotelInfo/HotelInfo";
// import { addGoogleData } from "../App/features/googleDataSlice";
// import { addNexusData } from "../App/features/nexusDataSlice";
// import GoogleData from "./HotelInfo/GoogleData";
// import NexusData from "./HotelInfo/NexusData";
// import { Grid } from "@mui/material";
// import OpenAiData from "./HotelInfo/OpenAIData";

// export default function HotelInfoLayout () {
//     const dispatch = useDispatch();
//     const hotelId = useSelector(state => state.detail.id);
//     const glData = useSelector(state => state.googleData);
//     const nxData = useSelector(state => state.nexusData);
//     const tenantId = useSelector(state => state.user.tenantId);
    
//     const [loading, setLoading] = useState(true);
//     const [googleLoading, setGoogleLoading] = useState(true);
//     const [openLoading, setOpenLoading] = useState(true);
//     const [err, setErr] = useState({error: false, message: ""});

//     const { refetch: nexusRefetch, data, isLoading, isSuccess, isError, error } = useGetNexusAnaysisQuery(hotelId);
//     const { refetch: googleRefetch, data: googleData, isLoading: googleLoad, isSuccess: googleSuc, isError: googleIsErr, error: googleErr } = useGetGoogleAnaysisQuery(hotelId);
//     const { refetch: openAiRefetch, data: openData, isLoading: openLoad, isSuccess: openSuc, isError: openIsErr, error: openErr } = useGetOpenAIAnaysisQuery(hotelId);
    
//     const updater = async (refetch, stoper, adder) => {
//         dispatch(apiSlice.util.resetApiState());
//         const obj = { tenant: `${tenantId}`, hotel: `${hotelId}` };

//         if (hotelId) {
//             const resp = await refetch({ forceRefetch: true }).then((response) => {
//                 stoper(false);
//                 console.log("Result:", response);

//                 if (response.isError) {
//                     setErr({ error: true, message: response });
//                 }
//                 if (response.data) {
//                     dispatch(adder(response.data.data));
//                 }
//             }).catch((err) => {
//                 stoper(false);
//                 setErr({ error: true, message: err.message });
//             });
//         }
//     };

//     useEffect(() => {
//         if (hotelId) {
//             updater(nexusRefetch, setLoading, addNexusData);
//             updater(googleRefetch, setGoogleLoading, addGoogleData);
//             updater(openAiRefetch, setOpenLoading, addHotel);
//         }
//     }, [hotelId, tenantId]);

//     if (loading || googleLoading || openLoading) {
//         return <FetchLoading />;
//     }

//     return (
//         <MainSideBar>
//             <>
//                 <Grid container spacing={3}>
//                     <Grid item xs={12} md={12} lg={12} xl={12}>
//                         <GoogleData/>
//                     </Grid>
//                     <Grid item xs={12} md={12} lg={12} xl={12}>
//                         <NexusData/>
//                     </Grid>
//                     <Grid item xs={12} md={12} lg={12} xl={12}>
//                         <OpenAiData/>
//                     </Grid>
//                 </Grid>
//             </>
//         </MainSideBar>
//     );
// }





import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { apiSlice, useGetNexusAnaysisQuery, useGetGoogleAnaysisQuery, useGetOpenAIAnaysisQuery } from "../Services/ApiServices";
import { addHotel } from "../App/features/hotelDetail";
import MainSideBar from "./SideBar";
import FetchLoading from "./Messages/FetchLoading";
import { addGoogleData } from "../App/features/googleDataSlice";
import { addNexusData } from "../App/features/nexusDataSlice";
import GoogleData from "./HotelInfo/GoogleData";
import NexusData from "./HotelInfo/NexusData";
import { Grid } from "@mui/material";
import OpenAiData from "./HotelInfo/OpenAIData";

export default function HotelInfoLayout() {
    const dispatch = useDispatch();
    const hotelId = useSelector(state => state.detail.id);
    const tenantId = useSelector(state => state.user.tenantId);
    
    const [loading, setLoading] = useState(true);
    const [googleLoading, setGoogleLoading] = useState(true);
    const [openLoading, setOpenLoading] = useState(true);
    const [nexusLoaded, setNexusLoaded] = useState(false);  // Add a flag to track Nexus load
    const [err, setErr] = useState({ error: false, message: "" });

    const { refetch: nexusRefetch } = useGetNexusAnaysisQuery(hotelId);
    const { refetch: googleRefetch } = useGetGoogleAnaysisQuery(hotelId);
    const { refetch: openAiRefetch } = useGetOpenAIAnaysisQuery(hotelId);
    
    const updater = async (refetch, stoper, adder) => {
        dispatch(apiSlice.util.resetApiState());

        if (hotelId) {
            try {
                const response = await refetch({ forceRefetch: true });
                stoper(false);

                if (response.isError) {
                    setErr({ error: true, message: response });
                }
                if (response.data) {
                    dispatch(adder(response.data.data));
                }
            } catch (err) {
                stoper(false);
                setErr({ error: true, message: err.message });
            }
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            if (hotelId) {
                // Fetch Nexus Data First
                await updater(nexusRefetch, setLoading, addNexusData);
                setNexusLoaded(true);  // Set Nexus flag to true after data is loaded
                
                // Fetch Google Data only after Nexus is done
                await updater(googleRefetch, setGoogleLoading, addGoogleData);
                
                // Finally, Fetch OpenAI Data
                await updater(openAiRefetch, setOpenLoading, addHotel);
            }
        };

        fetchData();
    }, [hotelId, tenantId]);

    if (loading || googleLoading || openLoading) {
        return <FetchLoading />;
    }

    return (
        <MainSideBar>
            <>
                <Grid container spacing={3}>


                           {/* Google Data comes next */}
                    <Grid item xs={12} md={12} lg={12} xl={12}>
                        {nexusLoaded && <GoogleData />} 
                    </Grid>
                    {/* Nexus Data comes first */}
                    <Grid item xs={12} md={12} lg={12} xl={12}>
                        <NexusData />
                    </Grid>

             

                    {/* OpenAI Data comes last */}
                    <Grid item xs={12} md={12} lg={12} xl={12}>
                        {nexusLoaded && <OpenAiData />} {/* Only render OpenAIData if Nexus is loaded */}
                    </Grid>
                </Grid>
            </>
        </MainSideBar>
    );
}
