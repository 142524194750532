import * as React from 'react';
import {useEffect, useState} from 'react'
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import { Button, Card, IconButton } from '@mui/material';
import { Add, Delete, Edit } from '@mui/icons-material';
import CreateAdmin from './CreateAdminialog';
import { useRef } from 'react';
import EditAdmin from './EditAdmin';
import DeleteAdmin from './DeleteAdmin';
import { apiSlice, useGetAdminsQuery } from '../../../Services/ApiServices';
import { useDispatch, useSelector } from 'react-redux';
import MainError from '../../../Components/Messages/MainError';
import FetchLoading from '../../../Components/Messages/FetchLoading';
import { addAdmins, removeAdmins } from '../../../App/features/adminsSlice';
import MainSideBar from '../../../Components/SideBar';
import DataGridTable from '../../../Components/Datagrid';



function getFullDate(dateTimeString) {
  try {
    const dateObj = new Date(dateTimeString);
    return dateObj.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
  } catch (error) {
    console.error('Error parsing ISO 8601 date string:', error.message);
    return ''; 
  }
}


export default function ViewAdminsTable() {
    const dispatch = useDispatch()
    const dataGridRef = useRef(null);
    const admins = useSelector(state=>state.admins)
    const [open, setOpen] = useState(false);
    const [tempList, setTempList] = useState([])
    const [openEdit, setOpenEdit] = useState(false);
    const [editData, setEditData] = useState({});
    const [openDelete, setOpenDelete] = useState(false);
    const [name, setName] = useState("");
    const [id, setId] = useState("");
    const { newData, refetch , isLoadingnew, newerror } = useGetAdminsQuery();
    const [err, setErr] = useState({error: false, message: ""})
    const [loading, setLoading] = useState(true)

    const updater = async () =>{
      dispatch(apiSlice.util.resetApiState())
      try{
      const res = await refetch({ forceRefetch: true }).then((response)=>{
      if(response.isError) {
        setLoading((prev) =>{ return false})
        setErr((prev) => { return {...prev, error: true, message: response}})
      }
      if(response.isSuccess) {
        dispatch(addAdmins(response.data.data.admins))
        setTempList((prev) =>{
          return response.data.data.admins.map((item)=>{
            return {
              id: item.id,
              firstName: item.firstName,
              lastName: item.lastName,
              email: item.email,
              created: getFullDate(item.createdAt),
              updated: getFullDate(item.updatedAt),
              actions: item    
            }
          })
        })
      }
    })
  } catch{
    
      console.error("Unexpected error:", err);
      setErr({ error: true, message: err.message || 'An unexpected error occurred' });
    

  }
        setLoading((prev) =>{ return false})
     
    
  }

    useEffect(()=>{
      updater()
    }, [])




  const columns = [
  {
    field: 'firstName',
    headerName: 'First name',
    width: "150",
    editable: false,
  },
  {
    field: 'lastName',
    headerName: 'Last name',
    width: 150,
    editable: false,
  },
  {
    field: 'email',
    headerName: 'Email',
    width: 250,
    editable: false,
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 180,
    editable: false,
  },
  {
    field: 'updated',
    headerName: 'Updated',
    width: 180,
    editable: false,
  },  
  {
    field: 'actions',
    headerName: 'Actions',
    width: 300,
    editable: false,
    renderCell: (params) => {
            return (
                <>
                    <Button color='success' onClick={(e)=>
                    {
                      setEditData((prev)=>{return params.formattedValue});
                      setOpenEdit((prev)=>{return true});
                    }}><Edit/></Button>
                    <Button color='error' onClick={(e)=>{
                      let tempName = `${params.formattedValue.firstName} ${params.formattedValue.lastName}`
                      setName((prev) => {return tempName})
                      let tempId = `${params.formattedValue.id}`
                      setId((prev) =>{ return `${tempId}` })
                      setOpenDelete((prev) => {return true})
                    }}><Delete color = "error" /></Button>
                    {/* <Button color='success' onClick={(e)=>
                    {
                      setEditData((prev)=>{return params.formattedValue});
                      setOpenEdit((prev)=>{return true});
                    }}>More
                    </Button> */}
                </>
            )}
          },  
        ];
        const addBtn = <Button style={{ margin:"2%" }} onClick={()=>{setOpen((prev)=>{return true})}} variant='outlined' color="success"><Add/> Add Admin</Button>
        return (
            <MainSideBar>
                {
                    loading ? <FetchLoading/> : 
                    <>
                        <CreateAdmin open={open} closer={setOpen} updater = {updater}/>
                        <EditAdmin open={openEdit} admin={editData} closer={setOpenEdit} updater = {updater}/>
                        <DeleteAdmin handleClose={setOpenDelete} name={name} open={openDelete} id={id} updater ={updater} />
                        <DataGridTable data={tempList} columns={columns} title={"Admins"} options={addBtn} />
                    </>
                }
            </MainSideBar>
          
  );
}
