import { createSlice } from "@reduxjs/toolkit";

const dashSlice = createSlice({
    name:"dashboard",
    initialState: {},
    reducers: {
        addDash:(state, action) => { return {...action.payload}},
        removeDash:(state) => { return {}}
    }
})


export default dashSlice.reducer
export const {addDash, removeDash} = dashSlice.actions;