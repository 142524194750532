import { useTheme } from "@emotion/react"
import { Button, Card, CardContent, CardHeader, Divider, Typography } from "@mui/material"
import { useSelector } from "react-redux"
import { Link, useNavigate } from "react-router-dom"

export default function DashHeader () {
    const dashboard = useSelector(state=>state.dashboard)
    const theme = useTheme()
    const now = new Date();
    const nav = useNavigate()

    const CustomeCard = ({title, length, where}) =>{
    const cardStyle = { 
    display: 'flex', 
    bgcolor: 'background.paper', 
    margin: "10px", 
    overflow:"hidden",
    width: '17%',
    boxShadow: "2px 2px 10px #5c5e5d",
    transition: 'transform 0.3s ease-in-out',
    ':hover' : {
      boxShadow: "5px 5px 15px #5c5e5d",
      transform: 'scale(1.25)'
    }
   }
      return <Card style={cardStyle}>
        <CardContent style={{ width:"100%" }}>
        <Typography variant="subtitle2" gutterBottom>
          {title}
        </Typography>
        <Divider/>
        <Typography variant="h2" component="div">
          {length}
        </Typography>
        <Divider />
        <Typography variant="body1">
          {` ${now.toLocaleString('default', { month: 'long' })} ${now.getDate()}, ${now.getFullYear()}`}
        </Typography>
        <Button variant="outlined" style= {{margin:"2%", width :"100%" }} onClick={()=>{nav(`/${where}`)}} >See All </Button>
      </CardContent>
    </Card>
    }
    return (
    <div style={{ display:"flex" ,width:"100%", justifyContent:"left" }}>
        <CustomeCard title={"Number of Branches"} length={dashboard.branches.length} where={"allbranches"} />
        <CustomeCard title={"Number of Regions"} length={dashboard.regions.length} where={"allregions"} />
        <CustomeCard title={"Number of Users"} length={dashboard.users.length} where={"allusers"} />
    </div>)
}