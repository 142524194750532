import { createSlice } from "@reduxjs/toolkit";

const branchSlice = createSlice({
    name:"branches",
    initialState: [],
    reducers: {
        addBranch:(state, action) => { return [ ...action.payload]},
        removeBranch:(state) => { return []}
    }
})


export default branchSlice.reducer
export const {addBranch} = branchSlice.actions;