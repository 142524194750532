import React, { useState } from 'react'
import Grid from '@mui/material/Grid';
import {TextField, Button,Typography,Stack,Chip,FormGroup,Checkbox, FormControlLabel, Select, MenuItem, Paper, InputLabel,FormControl, CircularProgress   } from '@mui/material/';
import { Add, LockOutlined, Save } from '@mui/icons-material';
import Avatar from '@mui/material/Avatar';
import Rating from '@mui/material/Rating';
import { useDispatch } from 'react-redux';
import {UploadFile} from "@mui/icons-material"
import store from '../../../../App/Store';
import {useSelector} from "react-redux"
import { useTheme } from '@emotion/react';
import CreateElement from '../../../../Utils/ElementCreator';
import {useNavigate, useParams} from "react-router-dom"
import { addToForm, removeForm } from '../../../../App/features/formSlice';
import { apiSlice, useGetFormsQuery, useSaveReviewAttributesMutation } from '../../../../Services/ApiServices';
import { addToLcForm, removeLcForm } from '../../../../App/features/localFormSlice';
import { MessageGenerator, type } from '../../../../Components/Messages/MesageGenerator';


const DsiplayForm=({formState})=>{
    const dispatch = useDispatch()
    const theme = useTheme()
    const nav = useNavigate()
    const { formsId } = useParams();
    const lcForm = useSelector(state => state.lcForm)
    const tenantId = useSelector(state=> state.user.tenantId)
    const [msg, setMsg] = useState({suc: false, message: ""})
    const [saveData, { isLoading, error, isSuccess }] = useSaveReviewAttributesMutation();
    const { data, refetch, isLoading: load, isError, error: feterr } = useGetFormsQuery(formsId);
    const [err, setErr] = useState({error: false, message: ""})
    const [loading, setLoading] = useState(true)
    const updater = async () =>{
      dispatch(apiSlice.util.resetApiState())
      const res = await refetch({ forceRefetch: true }).then((response)=>{
      if(response.isError) {
        setLoading((prev) =>{ return false})
        setErr((prev) => { return {...prev, error: true, message: response}})
      }
      if(response.isSuccess) {
        dispatch(addToForm(response.data.data.reviewAttributes))
        dispatch(addToLcForm(response.data.data.reviewAttributes))
        setLoading((prev) =>{ return false})
      }
    })
    
  }


    const saveForm = async() =>{
         const attributes = lcForm.map((item) => {
            return {
                title: item.title,
                is_default: item.is_default,
                input_type: item.input_type,
                is_required: item.is_required,
                options: item.options
            };
            });
    let mainData ={
    data: { parameters: attributes.map((item) =>{
      return {title: item.title,
              is_default: item.is_default,
              input_type: item.input_type,
              is_required: item.is_required,
              options: item.options}
    }) },
    id: formsId
  }
  console.log("The data is", mainData)
    const updated = await saveData(mainData).then((response) =>{
    console.log("resposssssssssssssssss", mainData , response);
          if(response.data) {
            setMsg((prev) => {return {...prev, suc: true, message: response.data.data.message}})
            updater()
            // dispatch(removeLcForm())
            // dispatch(removeForm())
          }
          if(response.error) {
            setErr((prev) =>{ return {...prev, error: true, message: response.error.data.message }})
          }
        })
    }

    const paperStyle={padding :20, margin:"0 auto"}
    const avatarStyle={backgroundColor: theme.palette.primary.main}
    const btnstyle={margin:'8px 0'}

    return(
        <form>
        <Paper style={paperStyle} elevation={5}>
        <Grid container gap={3} alignContent="center">
            
            <Grid xs={12}>
                    <h2 color={theme.palette.text.main}>Updated Form</h2>
            </Grid>
            
        { 
            lcForm.map((item) => {
            return CreateElement(item, theme, true)
          })
        }
        <div style={{ display: "flex", justifyContent:"center" }}>
        {isLoading && <CircularProgress style={{ margin:"auto 0" }} />}
        {(msg.suc && !isLoading) && <MessageGenerator type={type.suc} message={msg.message} />}
        {(err.error && !isLoading) && <MessageGenerator type={type.err} message={err.message} />}
        </div>
        <Grid xs={12} align={'center'}>
                    <Button variant='contained' size='small' color='warning' onClick={saveForm}><Save/> Save and Submit</Button>
            </Grid> 
        </Grid>
        </Paper>
        </form>
    )
}

export default DsiplayForm