import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/SearchSharp';
import Slide from '@mui/material/Slide';
import { Box, Button, Card, CardContent, CardMedia, Checkbox, CircularProgress, Divider, FormControlLabel, Grid, InputBase, TextField } from '@mui/material';
import { apiSlice, useAssignSenimentTenantMutation, useGetTenantTypeQuery, useRemoveSenimentTenantMutation, useSearchHotelMutation } from '../../../Services/ApiServices';
import { useTheme } from '@emotion/react';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import FetchLoading from '../../../Components/Messages/FetchLoading';
import { CheckBox } from '@mui/icons-material';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AssignTenantType({open, closer, assignData, updater}) {
  const [cropen, setCrOpen] = useState(false)
  const [formData, setFormData] = useState("")
  const theme = useTheme()
  const [tenantType, setTenantType] = useState([])
  const [mutuate, {data: dataResponse, isLoading: ldd, isError: eerx, error: errr}] = useAssignSenimentTenantMutation()
  const [mutuaterm , {isLoading: rmload}] = useRemoveSenimentTenantMutation()
  const [textSearch, settextSearch] = React.useState("")
  const [hotels, setHotels] = React.useState([])
  const [tempList, setTempList] = useState([])
    const [openDelete, setOpenDelete] = useState(false);
    const [name, setName] = useState("");
    const { data, refetch, isLoading, error } = useGetTenantTypeQuery()
    const dispatch = useDispatch()
    const [err, setErr] = useState({error: false, message: ""})
    const [loading, setLoading] = useState(true)
    const loaderType = async () =>{
      dispatch(apiSlice.util.resetApiState())
      const res = await refetch({ forceRefetch: true }).then((response)=>{
      if(response.isError) {
        setLoading((prev) =>{ return false})
        setErr((prev) => { return {...prev, error: true, message: response}})
      }
      if(response.isSuccess) {
        console.log("responsesssssssssssss ", response.data);
        setTempList((prev) =>{
          return response.data.data.tenant_types.map((item)=>{
              return {
            id: item.id,
            title: item.title,
            actions: item
        }
          })
        })
      }
      setLoading((prev) =>{ return false})
    })
  }

  const handleClose = () => {
    closer((prev) =>{ return false})
  };

  useEffect(()=>{
    loaderType()
  },[open])

  const assignTenantType = async () => {
    
    if(tenantType.length == 0) {
      alert("Please select at least one tenant type.")
    }
    else {
      await mutuate({values: tenantType}).then((response) =>{
        console.log("Response for ", response);
        updater()
      })

    }
  }


  const assignTenant = (e) => {
    e.preventDefault()
    if(e.target.checked) {
      setTenantType((prev) =>{
        return [...prev, {sentimentId: assignData.id, tenantTypeId: e.target.value }]
      })
    }
    else {
      setTenantType((prev) =>{
        return prev.filter((item) => item.tenantTypeId != e.target.value)
      })
    }
    console.log("list is ",tenantType)
  }
  


  return (
    <>
    
    <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Assign Tenant Type
            </Typography>
          </Toolbar>
        </AppBar>
        <Grid spacing={2} style={{ marginTop:"20px",marginLeft:"2%"}}  >
          {(Boolean(assignData)) && <>
           <Typography color="primary">Title : {Boolean(assignData.title) && assignData.title}</Typography> <br></br>
           <Typography color="primary">Prompt : {Boolean(assignData.prompt) && assignData.prompt}</Typography> <br></br>
           <Typography color="primary">Tenant Types </Typography><br></br>
           {
            Boolean(assignData.tenant_types?.length > 0) && <>
            {assignData.tenant_types.map((item) => {
              return <Typography color="primary">{item.title} <Button onClick={ async () =>{ 
                let ask = window.confirm(`are you sure you want to delete ${item.title}?`)
                if(ask){
                  await mutuaterm({values: [{sentimentId: assignData.id, tenantTypeId: item.id}]}).then((response) =>{
                    console.log("response delete ", response);
                    updater()
                  })
                } 
              }} color='warning'>Delete</Button></Typography>
            })}
            </>
           }
          </>}
          <Divider/>

          <div>
            <Typography variant="h5">Assign Type</Typography>
          {loading ? <FetchLoading/> : <>
            {
              tempList.map((item) => {
                return <FormControlLabel label={item.title} value={item.id} onChange={assignTenant} control={<Checkbox />} />
              })
            }
          </>}
          </div> 
          <Button variant='contained' type='submit' color='warning' onClick={assignTenantType} >Assign type</Button>  
        </Grid>
      </Dialog>
 </>
  )


}
