import { Checklist, ChecklistRtl, Dashboard, Home, Logout, People } from "@mui/icons-material"
import { useEffect, useState } from "react"
import  {Menu, MenuItem, Sidebar, SubMenu} from "react-pro-sidebar"
import MenuIcon from '@mui/icons-material/Menu';
import { Button, Collapse, Divider, IconButton, Typography } from '@mui/material'
import { useTheme } from "@emotion/react";
import Topbar from "./TopBar";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

import MenuGenerator from "./Menu";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";


export default function MainSideBar ({children}) {
    const [collapsed, setCollapsed] = useState(true)
    const role = useSelector(state=>state.user.role)
    const name = useSelector(state=>state.user.firstName)
    const theme = useTheme()
    const nav = useNavigate()
    const handleLogOut = () =>{
        sessionStorage.clear()
        nav("/login")
    } 
    const SideBarHeader = <div>
           {
                collapsed ? 
                <div style={{ display:"flex", justifyContent:"center",padding:"5%",margin:"2%", }}>
                    <Button size="l" onClick={() =>{ setCollapsed(false)}}><MenuIcon/></Button>
                </div>:
                <div style={{ display:"flex",padding:"5%",margin:"2%", justifyContent:"space-between" }}>
                    <Button size="l" onClick={() =>{ setCollapsed(true)}}>
                    <Typography variant="h4">
                        {(role==="tenantAdmin") ? "Tenant Admin" : role}
                        
                    </Typography>
                    </Button>
                    <Button size="l" onClick={() =>{ setCollapsed(true)}} startIcon={<KeyboardArrowLeftIcon/>}>
                    </Button>
                </div>
            }
            </div>

    return(
    <div style={{ display:"flex", width:"100%", justifyContent:"space-between" }}>
        <Sidebar sx={{ position:"sticky", top:"0"  }} backgroundColor = {theme.palette.background.paper} collapsed={collapsed} style={{ height:"100vh", }}>
             {SideBarHeader}
        <Divider/>
            <MenuGenerator style={{ backgroundColor:"black" }} />
        <Divider/>
        <Menu>
            <MenuItem onClick={handleLogOut} icon={<Logout color="primary"/>} ><Typography variant="body2" color={theme.palette.primary.main}>Log out</Typography></MenuItem>
        </Menu>
        </Sidebar>
        <main style={{ width:"100%",float:"right", backgroundColor: theme.palette.background.default}} >
            <Topbar />
            <h4 style={{ marginLeft:"1%", paddingLeft:"2%", marginBottom:"-2%" }}>👋 Hi {name}, Welcome.</h4>
            <div style={{ display:"flex", justifyContent:"left", padding:"2%", margin:"1%" }}>
                {children}
            </div>
        </main>
    </div>)
}
