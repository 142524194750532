import { createSlice } from "@reduxjs/toolkit";

const sentimentSlice = createSlice({
    name:"senti",
    initialState: [],
    reducers: {
        addSenti:(state, action) => { return [ ...action.payload]},
        removeSenti:(state) => { return []}
    }
})


export default sentimentSlice.reducer
export const {addSenti, removeSenti} = sentimentSlice.actions;