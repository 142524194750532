import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useDispatch, useSelector } from 'react-redux';
import { apiSlice, useGetTenantTypeQuery, usePostAdminMutation, usePostManagerMutation, usePostTenantMutation } from '../../../Services/ApiServices';
import FetchLoading from '../../../Components/Messages/FetchLoading';
import { MessageGenerator, msg, type } from '../../../Components/Messages/MesageGenerator';
import { CircularProgress, FormControl, IconButton, InputLabel, MenuItem, Select } from '@mui/material';
import { CloseOutlined } from '@mui/icons-material';

export default function CreateTenant({open, closer, updater}) {

    const dispatch = useDispatch()
    const [err, setErr] = useState({error: false, message: ""})
    const [msg, setMsg] = useState({suc: false, message: ""})
    const {data , refetch, isLoading: ldd, error: eern } = useGetTenantTypeQuery()
    const [mutate, { isLoading, isSuccess,isError, error }] = usePostTenantMutation()
    const [loading, setLoading] = useState(true)
    const [tempList, setTempList] = useState([])
    const [formData, setFormData] = useState({
      firstName: '',
      lastName: '',
      email: '',
      organizationName: '',
      tenantType: "",
    });
  const handleChange =async (event) => {
    setErr((prev) => {return {...prev, error: false, message:""}})
    setMsg((prev) => {return {suc: false, message: "" }})
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const loaderType = async () =>{
      dispatch(apiSlice.util.resetApiState())
      const res = await refetch({ forceRefetch: true }).then((response)=>{
        console.log("responsessssssssssssss ", response);
      if(response.isError) {
        setLoading((prev) =>{ return false})
        setErr((prev) => { return {...prev, error: true, message: response}})
      }
      if(response.isSuccess) {
        
        // dispatch(addManagers(response.data.data.managers))
        setTempList((prev) =>{
          return response.data.data.tenant_types.map((item)=>{
              return {
            id: item.id,
            title: item.title,
        }
          })
        })
        setLoading((prev) =>{ return false})
      }
    })
  }

  useEffect(()=>{
    loaderType()
  },[open])

  const handleSubmit = async (event) => {
    event.preventDefault();
    const { firstName, lastName, email, organizationName, tenantType } = formData;
    if (!firstName || !lastName || !email || !organizationName || !tenantType) {
      setErr((prev) => { return {...prev, error: true, message: 'Please fill in all required fields.'}})
    }
    else {
      console.log("Form Data", formData);
      
        const response = await mutate({formData: {...formData, password:"Eyob@123456"}})
        if(response.data) {
          setErr((prev) => {return {...prev, error: false, message:""}})
          setMsg((prev) => {return {suc:true, message: response.data.data.message }})
          setFormData((prev) =>{ return {
            firstName: '',
            lastName: '',
            email: '',
            organizationName: '',
            tenantType: "",
                  }})
          updater()
        }
        if(response.error) {
          setMsg((prev) => {return {suc:false, message: "" }})
          setErr((prev) => {return {...prev, error: true, message: response.error.data.data.message}})
        }
    }
  };

  return (
    <>
      <Dialog open={open} PaperProps={{ component: 'form' }}>
        <div style={{ padding:"1%" }}>
        <div style={{ width: "100%", display: "flex", justifyContent: "right" }}>
        <div style={{ width:"90%" }}>
        <DialogTitle>Add Tenant</DialogTitle>
        </div>
        <div>
          <IconButton color='error' >
            <CloseOutlined style={{ margin: "5px" }} onClick={()=>{closer((prev)=>{return false})}} />
          </IconButton>
        </div>
        </div>
        
        <DialogContent>
          <DialogContentText>
            To Add Tenant please fill the following information.
          </DialogContentText>
          <TextField
            autoFocus
            required
            margin="dense"
            id="firstName"
            name="firstName"
            label="First Name"
            type="text"
            fullWidth
            variant="standard"
            onChange={handleChange}
            value={formData.firstName}
          />
          <TextField
            required
            margin="dense"
            id="lastName"
            name="lastName"
            label="Last Name"
            type="text"
            fullWidth
            variant="standard"
            onChange={handleChange}
            value={formData.lastName}
          />
          <TextField
            required
            margin="dense"
            id="email"
            name="email"
            label="Email Address"
            type="email"
            fullWidth
            variant="standard"
            onChange={handleChange}
            value={formData.email}
          />
          <TextField
            required
            margin="dense"
            id="organizationName"
            name="organizationName"
            label="Organization Name"
            type="text"
            fullWidth
            variant="standard"
            onChange={handleChange}
            value={formData.organizationName}
          />

          {tempList && <FormControl  style={{ marginTop:"1%" }} fullWidth>
            <InputLabel id="tenanttype">Tenant Type</InputLabel>
            <Select
              size='small'
              labelId="tenanttype"
              id="tenanttype"
              value={formData.tenantType}
              label="Tenant Type"
              onChange={(event) => {setFormData((prev) =>  {return {...prev, tenantType: event.target.value}})}}
            >
              {
                tempList.map((item) =>{
                  return <MenuItem value={item.id}>{item.title}</MenuItem>
                })
              }
            </Select>
          </FormControl>}
        </DialogContent>
        <div style={{ display: "flex", justifyContent:"center" }}>
        {isLoading && <CircularProgress style={{ margin:"auto 0" }} />}
        {(msg.suc && !isLoading)  && <MessageGenerator type={type.suc} message={msg.message} />}
        {(err.error && !isLoading) && <MessageGenerator type={type.err} message={err.message} />}
        </div>
        <DialogActions>
          <Button onClick={()=>{closer((prev)=>{return false})}}>Cancel</Button>
          <Button type="submit" variant='contained' onClick={handleSubmit}>
            Create Tenant
          </Button>
        </DialogActions>
        </div>
      </Dialog>
    </>
  );
}
