import { useTheme } from '@emotion/react';
import { Delete, DisabledByDefault, Edit } from '@mui/icons-material';
import {TextField, Button,Typography,Stack,Chip,FormGroup,Checkbox, FormControlLabel, Select, MenuItem, Paper, InputLabel,FormControl, CircularProgress   } from '@mui/material/';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {useNavigate, useParams} from "react-router-dom"
import { addToForm, deleteForm, removeForm } from '../../../../App/features/formSlice';
import { useSaveReviewAttributesMutation } from '../../../../Services/ApiServices';
import store from "../../../../App/Store"
import { addToLcForm, deleteLcForm, removeLcForm } from '../../../../App/features/localFormSlice';
import { MessageGenerator, type } from '../../../../Components/Messages/MesageGenerator';
import { useState } from 'react';

const Table = ({ data ,onEdit, onDelete, setDataEdit, setTempForm, setDisable, updater}) => {
  const tenantId = useSelector(state=> state.user.tenantId)
  const mainForm = useSelector(state=>state.form)
  const { formsId } = useParams();
  const [saveData, { isLoading, error, isSuccess }] = useSaveReviewAttributesMutation();
  const nav = useNavigate()
    const [err, setErr] = useState({error: false, message: ""})
  const [msg, setMsg] = useState({suc: false, message: ""})
    const theme = useTheme()
    const dispatch = useDispatch()
    const thStyle = {
  backgroundColor: theme.palette.primary.main,
  padding: '12px',
  textAlign: 'left',
  borderBottom: '1px solid #ddd',
  color: "white"
};

const tdStyle = {
  padding: '12px',
  textAlign: 'left',
  borderBottom: '1px solid #ddd'
};

const askConfirmation = async (item) => {
    const confirmed = window.confirm('Are you sure you want to delete this item?')
    if(confirmed === true) {
        
        dispatch(deleteLcForm(item))
    }
}

const saveForm = async () =>{
  let mainData ={
    data: { parameters: data.map((item) =>{
      return {title: item.title,
              is_default: item.is_default,
              input_type: item.input_type,
              is_required: item.is_required,
              options: item.options}
    }) },
    id: formsId
  }
  console.log("The data is", mainData)
  const updated = await saveData(mainData).then((response) =>{
    console.log("resposssssssssssssssss",mainData,  response);
          if(response.data) {
            setMsg((prev) => {return {...prev, suc: true, message: response.data.data.message}})
            updater()
          }
          if(response.error) {
            setErr((prev) =>{ return {...prev, error: true, message: response.error.data.message }})
          }
        })

}


  return (
    <>
    <table style={{ borderCollapse: 'collapse', width: '100%' }}>
      <thead>
        <tr>
          <th style={thStyle}>S.No</th>
          <th style={thStyle}>Title</th>
          <th style={thStyle}>Input Type</th>
          <th style={thStyle}>Required</th>
          <th style={thStyle}>Actions</th>
        </tr>
      </thead>
      <tbody>
        {
        // console.log("heyyyyyyyyyyyyyyyyy", data)
        
        data.map((item, index) => (
          <tr key={index} style={{ borderBottom: '1px solid #ddd' }}>
            <td style={tdStyle}>{index + 1}</td>
            <td style={tdStyle}>{item.title}</td>
            <td style={tdStyle}>{item.input_type}</td>
            <td style={tdStyle}>{item.is_required ? 'Yes' : 'No'}</td>
            <td style={tdStyle}>
              {!item.is_default && <Button disabled={item.is_default} ><Edit color='warning' onClick={() => setDataEdit((prev) => {
                setDisable((prev) => { return true})
                return item})} /></Button>}
              {!item.is_default && <Button disabled={item.is_default} ><Delete color='error' onClick = {() => askConfirmation(item)} /></Button>}
                {/* <button onClick={() => onEdit(item)}>Edit</button> */}
              {/* <button style={buttonStyle} onClick={() => onDelete(item)}>Delete</button> */}
            </td>
          </tr>
        ))
        }
      </tbody>
    </table>
    <div style={{ display: "flex", justifyContent:"center" }}>
        {isLoading && <CircularProgress style={{ margin:"auto 0" }} />}
        {(msg.suc && !isLoading) && <MessageGenerator type={type.suc} message={msg.message} />}
        {(err.error && !isLoading) && <MessageGenerator type={type.err} message={err.message} />}
        </div>
    <Button variant='conained' onClick={()=>{
      nav(`/createForm/${formsId}`) 
      }} style={{ margin:"20px", backgroundColor: theme.palette.primary.main,color:"white" }}>Create New Fields</Button>
    <Button variant='contained' color='warning' onClick={saveForm}>Save and Close</Button>
    </>
  );
};

export default Table;
