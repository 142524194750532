import React, { useState } from 'react';
import { Card, CardContent, CardMedia, Typography, IconButton, Grid, Divider } from '@mui/material';
import { PlayArrow, Preview, PreviewOutlined, PreviewRounded, PreviewTwoTone, Visibility, WhereToVote, WhereToVoteRounded, WhereToVoteTwoTone } from '@mui/icons-material';
import { useGetDetailsQuery } from '../Services/ApiServices';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { addId } from '../App/features/hotelDetail';
import Alt from "../Assets/alt.jpg"
import { addAdindex } from '../App/features/adID';

const RegionCard = ({ regionName, id}) => {
  const tenantId = useSelector(state => state.user.tenantId )
  const dispatch = useDispatch()
  const navigater = useNavigate()
  const [loading, setLoading] = useState(false)
  const [opacity, setOpacity] = useState("100%")
  const placeId  = id
  // const { data: detailsData, isLoading, error } = useGetDetailsQuery(placeId, tenantId);
  const now = new Date();
  const fetchData = () =>{
    dispatch(addAdindex(id))
    navigater(`/adBranches`)
  }

  const cardStyle = { 
    display: 'flex', 
    bgcolor: 'background.paper', 
    margin: "10px", 
    overflow:"hidden",
    width: '23%',
    boxShadow: "2px 2px 5px #5c5e5d",
    transition: 'transform 0.3s ease-in-out',
    ':hover' : {
      boxShadow: "5px 5px 10px #5c5e5d",
      transform: 'scale(1.05)'
    }
   }

  return (
        <Card 
          onClick={() => fetchData()} sx={{ ...cardStyle }}  >
        <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column',  }}>
            <Typography variant='subtitle2'>Regions</Typography>
            <Typography variant='h5'>{regionName}</Typography>
            <Typography variant="body2">
            {` ${now.toLocaleString('default', { month: 'long' })} ${now.getDate()}, ${now.getFullYear()}`}
            </Typography>
            </CardContent>
      </Card>

  );
};

export default RegionCard;

