import * as React from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import {useDemoData} from '@mui/x-data-grid-generator';
import { Button } from '@mui/material';


export default function DataGridTable({columns ,data, title,options}) {
  return (
    <>
    <div style={{ height: 400, width: '100%' }}>
      <div style={{ display:"flex", justifyContent:"space-between" }}>
        <h4>{title}</h4>
        {options}
      </div>
        <DataGrid
        rows={data}
        columns={columns}
        slots={{ toolbar: GridToolbar }} 
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[5]}
        />
    </div>
    </>
  );
}
