import Alert from "@mui/material/Alert"
export const msg = {
    credentialWrong: "Wrong Email/Password!",
    adminRegisterd:"Successfully registered!",
    adminRegisterderr: "Something is Wrong. Unable to register",
    clientReviewSucc : "Review is Submitted!",
    clientReviewErr : "Review is Not Submitted!",
    sthErr : "Something is Wrong. Check your Connection and Try Again"
}
export const type = {
    err: "error",
    suc: "success",
    wrn: "warning",
    inf: "info",
}

export function MessageGeneratorClose({message, type, closeFunc = () =>{}}) {
    return (
        <Alert severity={type} onClose={() => {closeFunc()}}>
            {message}
        </Alert>
    )
}
export function MessageGenerator({message, type}) {
    return (
        <Alert severity={type}>
            {message}
        </Alert>
    )
}