import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import React from 'react'
import {useState} from 'react'
import { useDeleteHeadMutation, useDeleteRegionMutation } from '../../../Services/ApiServices';
import { MessageGenerator, type } from '../../../Components/Messages/MesageGenerator';

const DeleteRegion = ({open, name, headid, handleClose, updater}) => {
  const [err, setErr] = useState({error: false, message: ""})
  const [msg, setMsg] = useState({suc: false, message: ""})
  const [mutate, { isLoading, isSuccess,isError, error }] = useDeleteRegionMutation();
  const handleDelete = async () =>{
    const resp = await mutate(headid).then((response) =>{
      if(response.error) {
        setErr((prev) =>{ return {...prev, error: true, message: response.error.data.data.message}})
      }
      if(response.data){
        setMsg((prev) => { return { ...prev, suc: true, message: response.data.data.message}})
        updater()
      }
    })
  }
  const handleClosing = async () =>{
    setErr((prev) => {return {...prev, error: false, message:""}})
    setMsg((prev) => {return {suc: false, message: "" }})
    handleClose((prev) => {return false})
  }
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClosing}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle color="error" id="responsive-dialog-title">
          {"Delete Admin"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText color="main">
            Are you sure do you want to delete the Region {name} ?
          </DialogContentText>
        </DialogContent>
        <DialogContent style={{ width: "100%" }}>
          <div style={{ width:"100%", display:"flex", justifyContent:"center" }}>
            {isLoading && <CircularProgress style={{ margin:"auto 0" }} />}
             {(msg.suc && !isLoading) && <MessageGenerator type={type.suc} message={msg.message} />}
             {(err.error && !isLoading) && <MessageGenerator type={type.err} message={err.message} />}
          </div>
        </DialogContent>
        <DialogActions>
          <Button color="error" autoFocus onClick={handleDelete}>
            Delete
          </Button>
          <Button onClick={handleClosing} autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default DeleteRegion
