import { Button } from "@mui/material";
import DataGridTable from "../../../Components/Datagrid";
import MainSideBar from "../../../Components/SideBar";
import { Add, Delete, Edit } from "@mui/icons-material";
import { useEffect, useState } from "react";
import CreateTenantType from "./CreateTenantType";
import { useDispatch } from "react-redux";
import { apiSlice, useGetTenantTypeQuery } from "../../../Services/ApiServices";
import FetchLoading from "../../../Components/Messages/FetchLoading";
import EditTenantType from "./EditTenantType";
import DeleteTenantTypes from "./DeleteTenant";

export default function TenantType() {
    const [tempList, setTempList] = useState([])
    const [open, setOpen] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [editData, setEditData] = useState({});
    const [manId, setId] = useState("");
    const [openDelete, setOpenDelete] = useState(false);
    const [name, setName] = useState("");
    const { data, refetch, isLoading, error } = useGetTenantTypeQuery()
    const dispatch = useDispatch()
    const [err, setErr] = useState({error: false, message: ""})
    const [loading, setLoading] = useState(true)
     const updater = async () =>{
      dispatch(apiSlice.util.resetApiState())
      const res = await refetch({ forceRefetch: true }).then((response)=>{
      if(response.isError) {
        setLoading((prev) =>{ return false})
        setErr((prev) => { return {...prev, error: true, message: response}})
      }
      if(response.isSuccess) {
        console.log("responsesssssssssssss ", response.data);
        
        // dispatch(addManagers(response.data.data.managers))
        setTempList((prev) =>{
          return response.data.data.tenant_types.map((item)=>{
              return {
            id: item.id,
            title: item.title,
            actions: item
        }
          })
        })
        setLoading((prev) =>{ return false})
      }
    })
  }
    const testData = [{id:1, title:"Type 1"},{id:2, title:"Type 2"}, {id:3,title:"Type 3"}]
    const createTenant = <Button style={{ margin:"2%" }} onClick={() => {setOpen(true)}} variant="outlined"><Add/> Create Type</Button>
    const columns = [
    {
    field: 'title',
    headerName: 'Title',
    width: 600,
    editable: false,
   },  
  {
    field: 'actions',
    headerName: 'Actions',
    width: 180,
    editable: false,
    renderCell: (params) => {
            return (
                <>
                    <Button color='success' onClick={(e)=>
                    {
                      setEditData((prev)=>{return params.formattedValue});
                      setOpenEdit((prev)=>{return true});
                    }}><Edit/></Button>
                    <Button color='error' onClick={(e)=>{
                      let tempName = `${params.formattedValue.title}`
                      let tempId = params.formattedValue.id
                      setId((prev) => {return tempId})
                      setName((prev) => {return tempName})
                      setOpenDelete((prev) => {return true})
                    }}><Delete color = "error" /></Button>
                </>
            )}
          },  
        ];
    useEffect(()=>{
      updater()
    },[])
    return (
        <MainSideBar>
            <CreateTenantType open={open} closer={setOpen} updater = {updater}/>
            <DeleteTenantTypes handleClose={setOpenDelete} manId={manId} updater = {updater} name={name} open={openDelete} />
            <EditTenantType open={openEdit} admin={editData} closer={setOpenEdit} updater = {updater}/>
            {loading ? <FetchLoading/> :<DataGridTable title={"Tenant Type"} columns={columns} data ={tempList} options={createTenant} />}
        </MainSideBar>
    )
}