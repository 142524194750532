import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton } from '@mui/material'
import React, { useState } from 'react'
import { useDeleteFormsListMutation, useDeleteHeadMutation, useDeleteManagerMutation, useDeleteTenantTypesMutation } from '../../../Services/ApiServices';
import { MessageGenerator, msg, type } from '../../../Components/Messages/MesageGenerator';
import { CloseOutlined } from '@mui/icons-material';

const DeleteFormsList = ({open, name, manId, handleClose, updater}) => {
   const [err, setErr] = useState({error: false, message: ""})
  const [msg, setMsg] = useState({suc: false, message: ""})
  const [mutate, { isLoading, isSuccess,isError, error }] = useDeleteFormsListMutation()
const handleDelete = async () =>{
    const resp = mutate(manId).then((response) =>{
      if(response.error) {
        console.log(response);
        setErr((prev) =>{ return {...prev, error: true, message: response.error.data.data.message}})
      }
      if(response.data){
        setMsg((prev) => { return { ...prev, suc: true, message: response.data.data.message}})
        updater()
        handleClosing()
      }
    })
  }
  const handleClosing = () =>{
    setErr((prev) => {return {...prev, error: false, message:""}})
    setMsg((prev) => {return {suc: false, message: "" }})
    handleClose((prev) => {return false})
  }
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClosing}
        aria-labelledby="responsive-dialog-title"
      >
        <div style={{ padding:"1%" }}>
        <div style={{ width: "100%", display: "flex", justifyContent: "right" }}>
        <div style={{ width:"90%" }}>
        <DialogTitle color="error" id="responsive-dialog-title">
          {"Delete Type"}
        </DialogTitle>
        </div>
        <div>
          <IconButton color='error' >
            <CloseOutlined style={{ margin: "5px" }} onClick={()=>{handleClose((prev)=>{return false})}} />
          </IconButton>
        </div>
        </div>
        <DialogContent>
          <DialogContentText color="main">
            Are you sure do you want to delete the Form {name} ?
          </DialogContentText>
        </DialogContent>
        <DialogContent style={{ width: "100%" }}>
          <div style={{ width:"100%", display:"flex", justifyContent:"center" }}>
            {isLoading && <CircularProgress style={{ margin:"auto 0" }} />}
             {(msg.suc && !isLoading) && <MessageGenerator type={type.suc} message={msg.message} />}
             {(err.error && !isLoading) && <MessageGenerator type={type.err} message={err.message} />}
          </div>
        </DialogContent>
        <DialogActions>
          <Button color="error" variant='contained' onClick={handleDelete}>
            Delete
          </Button>
          <Button onClick={handleClosing} autoFocus>
            Cancel
          </Button>
        </DialogActions>
        </div>
      </Dialog>
    </>
  )
}

export default DeleteFormsList
