import { useDispatch, useSelector } from "react-redux";
import MainSideBar from "../../Components/SideBar";
import { apiSlice, useGetDashboardsQuery } from "../../Services/ApiServices";
import { useTheme } from "@emotion/react";
import { useEffect, useState } from "react";
import FetchLoading from "../../Components/Messages/FetchLoading";
import { Button, Card, CardContent, Divider, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function SuperAdmin() {
    const now = new Date()
    const nav = useNavigate()
    const dispatch = useDispatch()
    const [tenants, setTenants] = useState([])
    const theme = useTheme()
    const [err, setErr] = useState({error: false, message: ""})
    const [loading, setLoading] = useState(true)
    const { data, refetch, isLoading, error } = useGetDashboardsQuery();
    const updater = async () =>{
      dispatch(apiSlice.util.resetApiState())
      const res = await refetch({ forceRefetch: true }).then((response)=>{
        setLoading((prev) =>{ return false})
      if(response.isError) {
        setErr((prev) => { return {...prev, error: true, message: response}})
      }
      if(response.isSuccess) {
        setTenants((prev) =>{ return response.data.data.tenants})
      }
    })
  }

  const CustomeCard = ({title, length, where}) =>{
    const cardStyle = { 
    display: 'flex', 
    bgcolor: 'background.paper', 
    margin: "10px", 
    overflow:"hidden",
    width: '200px',
    boxShadow: "2px 2px 10px #5c5e5d",
    transition: 'transform 0.3s ease-in-out',
    ':hover' : {
      boxShadow: "5px 5px 15px #5c5e5d",
      transform: 'scale(1.25)'
    }
   }
      return <Card style={cardStyle}>
        <CardContent style={{ width:"100%" }}>
        <Typography variant="subtitle2" gutterBottom>
          {title}
        </Typography>
        <Divider/>
        <Typography variant="h2" component="div">
          {length}
        </Typography>
        <Divider />
        <Typography variant="body1">
          {` ${now.toLocaleString('default', { month: 'long' })} ${now.getDate()}, ${now.getFullYear()}`}
        </Typography>
        <Button variant="outlined" style= {{margin:"2%", width :"100%" }} onClick={()=>{nav(`/${where}`)}} >See All </Button>
      </CardContent>
    </Card>
  }



  useEffect(()=>{
    updater()
  },[])
    return (
        <MainSideBar>
            {loading ? <FetchLoading/> : <CustomeCard title={"Number of Tenants"} key={"1"} length={tenants.length} where={"viewTenants"} />}
        </MainSideBar>
    )
}