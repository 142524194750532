import { Box, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { apiSlice, useGetNexusAnaysisQuery } from "../../Services/ApiServices";
import { addGoogleData } from "../../App/features/googleDataSlice";
import LoadingSpinner from "../Messages/LoadingSpinner";
import { useEffect, useState } from "react";
import ChartPage from './LineGraph'; // Assuming LineGraph can handle both line and donut charts
import ApexDonutChartWithDetails from './ApexDonutChartWithDetails'; // Import the donut chart component

export default function NexusData() {
  const dispatch = useDispatch();
  const hotelId = useSelector(state => state.detail.id);
  const nexusState = useSelector(state => state.googleData);
  const [loading, setLoading] = useState(true);
  const [err, setErr] = useState({ error: false, message: "" });
  const { refetch } = useGetNexusAnaysisQuery(hotelId);

  const updater = async () => {
    dispatch(apiSlice.util.resetApiState());
    if (hotelId) {
      console.log('Fetching data for hotelId:', hotelId); // Debugging output
      try {
        const response = await refetch({ forceRefetch: true });
        console.log('API Response:', response); // Debugging output

        if (response.isError) {
          console.error('API Error:', response.error); // Use console.error for errors
          setErr({ error: true, message: response.error.message });
        } else if (response.data) {
          console.log('API Data:', response.data.data); // Debugging output
          dispatch(addGoogleData(response.data.data));
        } else {
          console.warn('API Response does not contain data:', response); // Warning if response is not as expected
        }
      } catch (error) {
        console.error('Fetch Error:', error); // Use console.error for errors
        setErr({ error: true, message: error.message });
      } finally {
        setLoading(false);
      }
    } else {
      console.warn('No hotelId found. Skipping data fetch.'); // Warning if hotelId is not available
      setLoading(false); // Ensure loading is false if hotelId is not present
    }
  };

  useEffect(() => {
    updater();
  }, [hotelId]); // Add hotelId to dependency array to refetch when it changes

  // Extract data for charts
  const reviewsForLineGraph = nexusState?.reviewsForLineGraph || [];
  const sentiments = nexusState?.sentiments || {};
  const sentimentsForLineGraph = nexusState?.sentimentsForLineGraph || {};

  // Prepare dynamic data for the donut chart
  const sentimentData = Object.keys(sentiments).reduce((acc, key) => {
    acc[key] = sentiments[key] || 0;
    return acc;
  }, {});

  console.log('Sentiment Data:', sentimentData); // Debugging output
  console.log('reviewsForLineGraph:', reviewsForLineGraph); // Debugging output
  console.log('sentimentsForLineGraph:', sentimentsForLineGraph); // Debugging output

  return (
    <Box width={"100%"}>  
      {loading ? (
        <LoadingSpinner />
      ) : err.error ? (
        <div>Error: {err.message}</div>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12} xl={12} lg={12} md={12}>
            <ChartPage reviewsForLineGraph={reviewsForLineGraph} sentimentsForLineGraph={sentimentsForLineGraph} />
          </Grid>
          <Grid item xs={12} xl={12} lg={12} md={12}>
            <ApexDonutChartWithDetails sentiments={sentimentData} />
          </Grid>
        </Grid>
      )}
    </Box>
  );
}
