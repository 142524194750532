import React from 'react';
import './table.css'


function ReviewsTable({ reviewsArray }) {

    let tableHeads = []
    if(reviewsArray?.reviews.length > 0) {
        tableHeads = Object.keys(reviewsArray?.reviews[0])
    }
    const helper = (review, tableHeads ) => {
        let a = []
        for(let i of tableHeads ) {
              a.push(<td>{review[i]}</td>)
        }
        return a
    }

    const table = <table border="1">
            <thead>
                <tr>
                    {
                        tableHeads.map((item) => {
                            return <th>{item}</th>
                        })
                    }
                </tr>
            </thead>
            <tbody>
                {(reviewsArray?.reviews?.length > 0) && reviewsArray.reviews.map((review, index)=>(
                    <tr key={index}>
                        {
                            helper(review, tableHeads)
                        }
                    </tr>
                ))}
            </tbody>
        </table>
    return (
        <>
            {reviewsArray && table}
        </>
    )
    }

export default ReviewsTable