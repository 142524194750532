

import { Button, Menu, MenuItem } from "@mui/material";
import DataGridTable from "../../../Components/Datagrid";
import MainSideBar from "../../../Components/SideBar";
import { Add, Delete, Edit } from "@mui/icons-material";
import { useEffect, useState } from "react";
import CreateSentiment from "./CreateSentiment";
import { apiSlice, useGetSentimentsQuery } from "../../../Services/ApiServices";
import { useDispatch } from "react-redux";
import FetchLoading from "../../../Components/Messages/FetchLoading";
import { addSenti } from "../../../App/features/sentimentSlice";
import ViewSentiment from "./ViewSentiment";
import EditSentiment from "./EditTenant";
import DeleteSenti from "./DeleteSenti";
import AssignTenantType from "./AssignTenantType";

export default function Sentiments() {
    const [open, setOpen] = useState(false)
    const [openAssign, setOpenAssign] = useState(false)
    const [assignData, setAssignData] = useState({})
    const [sentiData, setSentiData] = useState({})
    const [openDetail, setOpenDetail] = useState(false)
    const [tempList, setTempList] = useState([])
    const [openEdit, setOpenEdit] = useState(false);
    const [editData, setEditData] = useState({});
    const [manId, setId] = useState("");
    const [openDelete, setOpenDelete] = useState(false);
    const [name, setName] = useState("");
    const { data, refetch, isLoading, error } = useGetSentimentsQuery()
    const dispatch = useDispatch()
    const [err, setErr] = useState({error: false, message: ""})
    const [loading, setLoading] = useState(true)
     const updater = async () =>{
      dispatch(apiSlice.util.resetApiState())
      const res = await refetch({ forceRefetch: true }).then((response)=>{
      if(response.isError) {
        setLoading((prev) =>{ return false})
        setErr((prev) => { return {...prev, error: true, message: response}})
      }
      if(response.isSuccess) {
        console.log("Heloooooooooooooooooo ", response.data.data.sentiments);
        dispatch(addSenti(response.data.data.sentiments))
        setTempList((prev) =>{
          return response.data.data.sentiments.map((item)=>{
              return {
            id: item.id,
            title:item.title,
            prompt: item.prompt,
            actions: item
        }
          })
        })
        setLoading((prev) =>{ return false})
      }
    })
  }
    const [anchorEl, setAnchorEl] = useState(null);
    const openMenu = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        updater();
    }, []);

    const createTenant = <Button style={{ margin: "2%" }} onClick={() => { setOpen(true) }} variant="outlined"><Add />Create Sentiment</Button>;
    const columns = [
    {
    field: 'title',
    headerName: 'Title',
    width: 300,
    editable: false,
   },  
    {
    field: 'prompt',
    headerName: 'Prompt',
    width: 350,
    editable: false,
   },  
  {
    field: 'actions',
    headerName: 'Actions',
    width: 500,
    editable: false,
    renderCell: (params) => {
            return (
                <>
                    <Button color='success' onClick={(e)=>
                    {
                      setSentiData((prev)=>{return params.formattedValue});
                      setOpenDetail((prev)=>{return true});
                    }}>View</Button>
                    <Button color='success' onClick={(e)=>
                    {
                      setEditData((prev)=>{return params.formattedValue});
                      setOpenEdit((prev)=>{return true});
                    }}><Edit/></Button>
                    <Button color='error' onClick={(e)=>{
                      let tempName = `${params.formattedValue.title}`
                      let tempId = params.formattedValue.id
                      setId((prev) => {return tempId})
                      setName((prev) => {return tempName})
                      setOpenDelete((prev) => {return true})
                    }}><Delete color = "error" /></Button>
                </>
            )}
          },  
        ];
    return (
        <MainSideBar>
            <CreateSentiment open={open} closer={setOpen} updater={updater} />
            <ViewSentiment open={openDetail} data={sentiData} closer={setOpenDetail} />
            <AssignTenantType open={openAssign} updater={updater} assignData={assignData} closer={setOpenAssign} />
            <DeleteSenti handleClose={setOpenDelete} manId={manId} updater={updater} name={name} open={openDelete} />
            <EditSentiment open={openEdit} admin={editData} closer={setOpenEdit} updater={updater} />
            {loading ? <FetchLoading /> : <DataGridTable title={"Sentiments"} columns={columns} data={tempList} options={createTenant} />}
        </MainSideBar>
    );
}



// title: item.title || 'No Title', // Provide a fallback value
// prompt: item.prompt || 'No Prompt', // Provide a fallback value
// tenant_types: params.formattedValue.tenant_types || [] // Provide a fallback value