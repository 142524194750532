import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useSelector } from 'react-redux';
import { usePostAdminMutation, useUpdateFormsListMutation, useUpdateManagerMutation } from '../../../Services/ApiServices';
import { MessageGenerator, msg, type } from '../../../Components/Messages/MesageGenerator';
import { CloseOutlined } from '@mui/icons-material';
import { CircularProgress, IconButton } from '@mui/material';


export default function EditFormsList({admin,open, closer, updater}) {
  // console.log(admin);
  const userId = admin.id
  const [err, setErr] = useState({error: false, message: ""})
  const [msg, setMsg] = useState({suc: false, message: ""})
  //   const [open, setOpen] = useState(false);
  const [mutate, { isLoading, isSuccess,isError, error }] = useUpdateFormsListMutation()
  const tenantId = useSelector(state => state.user.tenantId)
  const [formData, setFormData] = useState({});
  useEffect(()=>{
      if(admin) {
        setFormData(
          (prev) => {
            return {
                    title: admin.title,
                    price: admin.price,
                  }
          }
        )
      }
  }, [admin])

  const handleChange =async (event) => {
    setErr((prev) => {return {...prev, error: false, message:""}})
    setMsg((prev) => {return {suc: false, message: "" }})

    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  // if(isSuccess) {
  //   updater()
  // }

  const handleSubmit = async (event) => {
    event.preventDefault();
    const { price ,title } = formData;
    if (!price || !title) {
      setErr((prev) =>{ return {...prev, error: true, message: "Please Fill all the Fields" }})
    }
     else {
      try {
        const resp = await mutate({ data: formData, id: userId }).then((response) =>{
          if(response.data) {
            setMsg((prev) => {return {...prev, suc: true, message: response.data.data.message}})
            updater()
          }
          if(response.error) {
            setErr((prev) =>{ return {...prev, error: true, message: response.error.data.message }})
          }
        })
      } catch (error) {
        setErr((prev) =>{ return {...prev, error: true, message: error }})
      }

    }
  };

  return (
    <>
      <Dialog open={open} PaperProps={{ component: 'form' }}>
        <div style={{ padding:"1%" }}>
        <div style={{ width: "100%", display: "flex", justifyContent: "right" }}>
        <div style={{ width:"90%" }}>
        <DialogTitle>Edit Forms List</DialogTitle>
        </div>
        <div>
          <IconButton color='error' >
            <CloseOutlined style={{ margin: "5px" }} onClick={()=>{closer((prev)=>{return false})}} />
          </IconButton>
        </div>
        </div>
        
        <DialogContent>
          <DialogContentText>
            Edit the Forms List Information.
          </DialogContentText>
          <TextField
            autoFocus
            required
            margin="dense"
            id="title"
            name="title"
            label="Title"
            type="text"
            fullWidth
            variant="standard"
            onChange={handleChange}
            value={formData.title}
          />
          <TextField
            required
            margin="dense"
            id="price"
            name="price"
            label="Price"
            type="number"
            fullWidth
            variant="standard"
            onChange={handleChange}
            value={formData.price}
          />
        </DialogContent>
        <div style={{ display: "flex", justifyContent:"center" }}>
        {isLoading && <CircularProgress style={{ margin:"auto 0" }} />}
        {(msg.suc && !isLoading) && <MessageGenerator type={type.suc} message={msg.message} />}
        {(err.error && !isLoading) && <MessageGenerator type={type.err} message={err.message} />}
        </div>
        <DialogActions>
          <Button onClick={()=>{closer((prev)=>{return false})}}>Cancel</Button>
          <Button type="submit" variant='contained' onClick={handleSubmit}>
            Save Change
          </Button>
        </DialogActions>
        </div>
      </Dialog>
    </>
  );
}
