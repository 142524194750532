import { Box, Button, Card, Divider, Grid, Paper, Rating, Typography } from "@mui/material";
import ImageCarousel from "./ImageCarousel";
import { useDispatch, useSelector } from "react-redux";
import { apiSlice, useGetGoogleAnaysisQuery } from "../../Services/ApiServices";
import { addGoogleData } from "../../App/features/googleDataSlice";
import FetchLoading from "../Messages/FetchLoading";
import { useEffect, useState } from "react";
import { useTheme } from "@emotion/react";
import { Place } from "@mui/icons-material";
import Gallery from "./ImageGallery";
import LoadingSpinner from "../Messages/LoadingSpinner";

export default function GoogleData () {
    const dispatch = useDispatch()
    const theme = useTheme()
    const hotelId = useSelector(state => state.detail.id)
    const [loading, setLoading] = useState(true)
    const hotel = useSelector(state=>state.googleData)
    const [err, setErr] = useState({error: false, message: ""})
    const {refetch , data,  isLoading, isSuccess, isError, error }= useGetGoogleAnaysisQuery(hotelId)

    const updater = async () =>{
      dispatch(apiSlice.util.resetApiState())
        if(hotelId){
            const resp = await refetch({ forceRefetch: true }).then((response)=>{
            setLoading((prev) =>{ return false})
            console.log("Result:", response)
        if(response.isError) {
            setLoading((prev) =>{ return false})
            setErr((prev) => { return {...prev, error: true, message: response}})
        }
        if(response.data) {


            dispatch(addGoogleData(response.data.data))
        }
        })

        }
  }
    useEffect(()=>{
        updater()
    },[])
    const ImageGallery = <Box sx={{ backgroundColor:"black", display:"flex", justifyContent:"center" }}>
             <Gallery/>
        </Box>
    const Displayer = <Paper sx={{ width:"100%", padding:"2%", height:"auto"}}>
            <Button variant='outlined' onClick={() =>{ window.history.go(-1); return false;}}>Go Back</Button>
        <Grid container spacing={2}>
            <Grid item xs={12} xl={12} lg={12} md={12}>
                <Card sx={{ backgroundColor:theme.palette.primary.main, padding:"1%" }}>
                    <Typography variant="h6" color="white">
                        Place Name : {hotel.displayName}
                    </Typography>
                    <Typography variant="h6" color="white">
                        Place Address : {hotel.formattedAddress}
                    </Typography>
                    <Typography variant="h6" color="white">
                        Rating : {hotel.rating}
                    </Typography>
                    <Rating name="read-only" value={hotel.rating} precision={0.25} readOnly />
                </Card>
            </Grid>
            
            <Grid item xs={12} xl={12} lg={12} md={12}>
                {ImageGallery}
            </Grid>
            <Grid item xs={12} xl={12} lg={12} md={12}>
                <Typography variant="h6" color="primary">
                    Editorial Summary
                </Typography>
                <Divider/>
                <Typography variant="body1">
                    {hotel.editorialSummary}
                </Typography>
            </Grid>
            <Grid item xs={12} xl={12} lg={12} md={12}>
                
            </Grid>
        </Grid>            
        </Paper>

    return (
        <Box width={"100%"}>
            {loading ? <LoadingSpinner/>:  Boolean(hotel) && <>
           {Displayer}
            </> }
        </Box>
    )
}