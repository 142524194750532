import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux";
import { apiSlice, useGetFormsQuery } from "../../../Services/ApiServices";
import { Button, Card, CardHeader, Divider, Grid, Typography } from "@mui/material";
import React from 'react';
import CardContent from '@mui/material/CardContent';
import { ArrowBack, Edit } from "@mui/icons-material";
import CreateElement from "../../../Utils/ElementCreator";
import { useTheme } from "@emotion/react";
import { useNavigate, useParams } from "react-router-dom";
import { addToForm } from "../../../App/features/formSlice";
import { removeLcForm } from "../../../App/features/localFormSlice";
import { useState } from "react";
import FetchLoading from "../../../Components/Messages/FetchLoading";
import MainSideBar from "../../../Components/SideBar";


export default function ViewForm() {
    const theme = useTheme()
    const navigator = useNavigate()
    const dispatch = useDispatch()
    const [err, setErr] = useState({error: false, message: ""})
    const [loading, setLoading] = useState(true)
    const tenantId = useSelector(state => state.user.tenantId)
    const forms = useSelector(state=>state.form)
    const { formsId } = useParams();
    console.log("I dis ssssssssssss", formsId)
    const { data, refetch, isLoading, isSuccess, isError, error } = useGetFormsQuery(formsId);
    
    useEffect(()=>{
        updater()
    },[])

     const updater = async () =>{
      dispatch(apiSlice.util.resetApiState())
      const res = await refetch({ forceRefetch: true }).then((response)=>{
        console.log("Response ut ", response);
      if(response.isError) {
        setLoading((prev) =>{ return false})
        setErr((prev) => { return {...prev, error: true, message: response}})
      }
      if(response.isSuccess) {
        console.log("Response success ", response.data.data.reviewAttributes)
        dispatch(addToForm(response.data.data.reviewAttributes))
        setLoading((prev) =>{ return false})
      }
    })
    
  }

    
    return (
      <MainSideBar>
        <>
        {loading ? <FetchLoading/> :
        <div style={{ width:"100%" }}>

        <Button variant='outlined' onClick={()=>{ window.history.back(); }} ><ArrowBack/> Go Back</Button>
        <div style={{ width:"100%", display:"flex", justifyContent:"center" }}>
            <Card sx={{ width: "40%", borderRadius:"2%", marginTop:"5px", textAlign:"center" }} elevation={2}>
              <Typography style={{ margin:"0 auto", margin:"3%" }} gutterBottom variant="h4" component="div">
                    Review Form
              </Typography>
              <Divider/>
            <CardContent>
                <Grid textAlign={"center"}>
                </Grid>
                <Grid>
                {forms.map((item) =>{
                    return <>
                    {CreateElement(item,theme)}
                    <Divider color={theme.palette.primary.main}/>
                    </>
                    } )}
                </Grid>
            </CardContent>
            <Divider/>
            <Button onClick={()=>{navigator(`/editform/${formsId}`)}} style={{ margin:"10px" }} variant="outlined" color="warning"><Edit/>Edit Form</Button>
            </Card>
            </div>
        </div>
        }
        </>
      </MainSideBar>
    )
}