import React from 'react';
import ReactMarkdown from 'react-markdown';

const TooltipContent = ({ sentiment, count, percentage, summary }) => {
  const markdownContent = `
${sentiment}
    Count: ${count}  
    ${percentage}% of reviewers gave this rating  
    Summary: 
    ${summary || 'No summary available'}
  `;

  return (
    <div style={{ width: '800px', padding: '10px' }}>
      <ReactMarkdown>{markdownContent}</ReactMarkdown>
    </div>
  );
};

export default TooltipContent;
