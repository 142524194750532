import { createSlice } from "@reduxjs/toolkit";

const tenantTypeSlice = createSlice({
    name:"tenantType",
    initialState: {},
    reducers: {
        addTenantType:(state, action) => { return [ ...action.payload]},
        removeTenantType:(state) => { return []}
    }
})


export default tenantTypeSlice.reducer
export const {addTenantType, removeTenantType} = tenantTypeSlice.actions;