import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { Margin } from '@mui/icons-material';
import { Card } from '@mui/material';

export default function FetchLoading() {
  return (
    <div style={{ 
      display:"flex", 
      flexDirection:"rowcolumn",
      marginTop:"auto",
      justifyContent:"center",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",  
       }}>
    <Box sx={{ display: 'flex' }}>
      <div style={{ padding:"20px", opacity:"100%" }}>
      <div style={{ width:"100%",display:"flex", justifyContent:"center",}}>
        <CircularProgress style={{ margin:"auto 0" }} />
      </div>
      <div style={{ textAlign:"center"}}>
        <h5>Loading...</h5>
      </div>
      </div>
    </Box>
    </div>
  );
}