// import { Box, Button, Card, Divider, Grid, Paper, Rating, Typography } from "@mui/material";
// import ImageCarousel from "./ImageCarousel";
// import { useDispatch, useSelector } from "react-redux";
// import { apiSlice, useGetGoogleAnaysisQuery, useGetNexusAnaysisQuery, useGetOpenAIAnaysisQuery } from "../../Services/ApiServices";
// import { addGoogleData } from "../../App/features/googleDataSlice";
// import FetchLoading from "../Messages/FetchLoading";
// import { useEffect, useState } from "react";
// import { useTheme } from "@emotion/react";
// import { Place } from "@mui/icons-material";
// import Gallery from "./ImageGallery";
// import { addNexusData } from "../../App/features/nexusDataSlice";
// import LoadingSpinner from "../Messages/LoadingSpinner";
// import { addOpenAiData } from "../../App/features/openAiSlice";
// import ReviewsTable from "./ReviewTable";
// import extractJSONFromString from "../../Utils/JSONExtractor";

// export default function OpenAiData () {
//     const dispatch = useDispatch()
//     const theme = useTheme()
//     const hotelId = useSelector(state => state.detail.id)
//     const nexusState= useSelector(state=>state.googleData)
//     const [loading, setLoading] = useState(true)
//     const hotel = useSelector(state=>state.openAiData)
//     const [err, setErr] = useState({error: false, message: ""})
//     const {refetch , data,  isLoading, isSuccess, isError, error }= useGetOpenAIAnaysisQuery(hotelId)

//     const updater = async () =>{
//       dispatch(apiSlice.util.resetApiState())
//         if(hotelId){
//             const resp = await refetch({ forceRefetch: true }).then((response)=>{
//             setLoading((prev) =>{ return false})
//             console.log("open AI Response ",response);
//         if(response.isError) {
//             setLoading((prev) =>{ return false})
//             setErr((prev) => { return {...prev, error: true, message: response}})
//         }
//         if(response.data) {
//             dispatch(addGoogleData(response.data.data))
//         }
//         })

//         }
//   }
//     useEffect(()=>{
//         updater()
//     },[])
    

//     return (
//         <Box width={"100%"}>
//             {loading ? <LoadingSpinner/>: <Box>
//                 <Paper sx={{ padding:"2%" }}>
//                 <Typography variant="h5" color="primary">Classification</Typography>
//                 <Divider/>
//                 <ReviewsTable reviewsArray={extractJSONFromString(nexusState.classification)} />
//                 <Divider/>
//                 <Typography variant="h5" color="primary">Summary</Typography>
//                 <Divider/>
//                 <Typography variant="body1">{nexusState.summary}</Typography>
//                 </Paper>
//             </Box> }
//         </Box>
//     )
// }


import { Box, Button, Card, Divider, Grid, Paper, Rating, Typography } from "@mui/material";
import ImageCarousel from "./ImageCarousel";
import { useDispatch, useSelector } from "react-redux";
import { apiSlice, useGetGoogleAnaysisQuery, useGetNexusAnaysisQuery, useGetOpenAIAnaysisQuery } from "../../Services/ApiServices";
import { addGoogleData } from "../../App/features/googleDataSlice";
import FetchLoading from "../Messages/FetchLoading";
import { useEffect, useState } from "react";
import { useTheme } from "@emotion/react";
import { Place } from "@mui/icons-material";
import Gallery from "./ImageGallery";
import { addNexusData } from "../../App/features/nexusDataSlice";
import LoadingSpinner from "../Messages/LoadingSpinner";
import { addOpenAiData } from "../../App/features/openAiSlice";
import ReviewsTable from "./ReviewTable";
import extractJSONFromString from "../../Utils/JSONExtractor";

export default function OpenAiData () {
    const dispatch = useDispatch()
    const theme = useTheme()
    const hotelId = useSelector(state => state.detail.id)
    const nexusState= useSelector(state=>state.googleData)
    const [loading, setLoading] = useState(true)
    const hotel = useSelector(state=>state.openAiData)
    const [err, setErr] = useState({error: false, message: ""})
    const {refetch , data,  isLoading, isSuccess, isError, error }= useGetOpenAIAnaysisQuery(hotelId)

    const updater = async () =>{
      dispatch(apiSlice.util.resetApiState())
        if(hotelId){
            const resp = await refetch({ forceRefetch: true }).then((response)=>{
            setLoading((prev) =>{ return false})
            console.log("open AI Response ",response);
        if(response.isError) {
            setLoading((prev) =>{ return false})
            setErr((prev) => { return {...prev, error: true, message: response}})
        }
        if(response.data) {
            dispatch(addGoogleData(response.data.data))
        }
        })

        }
  }
    useEffect(()=>{
        updater()
    },[])
    

    return (
        <Box width={"100%"}>
            {loading ? <LoadingSpinner/>: <Box>
                <Paper sx={{ padding:"2%" }}>
                <Typography variant="h5" color="primary">High attention</Typography>
                <Divider/>
                {/* <ReviewsTable reviewsArray={extractJSONFromString(nexusState.classification)} /> */}
                <ReviewsTable 
  reviewsArray={
    nexusState.classification && typeof nexusState.classification === 'string'
      ? extractJSONFromString(nexusState.classification)
      : null
  }
/>
                <Divider/>
                <Typography variant="h5" color="primary">Summary</Typography>
                <Divider/>
                <Typography variant="body1">{nexusState.summary}</Typography>
                </Paper>
            </Box> }
        </Box>
    )
}
