import { Box, Button, Card } from "@mui/material"
import { DataGrid } from "@mui/x-data-grid"
import { useSelector } from "react-redux"
import MainSideBar from "../../Components/SideBar"
import { ArrowBack } from "@mui/icons-material"
import DataGridTable from "../../Components/Datagrid"

export default function AllRegions() {
    const data = useSelector(state=>state.dashboard.regions)
const columns = [
  {
    field: 'name',
    headerName: 'Region Name',
    width: 450,
    editable: false,
  },
  {
    field: 'headName',
    headerName: 'Head Name',
    width: 350,
    editable: false,
  }, 
];
const goback = <Button variant='outlined' style={{ margin:"2%" }} onClick={() =>{ window.history.go(-1); return false;}}><ArrowBack/> Go Back</Button>
return (
  <MainSideBar>
    <DataGridTable data={data} columns={columns} options={goback} />
  </MainSideBar>
)

}