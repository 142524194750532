import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useSelector } from 'react-redux';
import { usePostAdminMutation, usePostManagerMutation } from '../../../Services/ApiServices';
import FetchLoading from '../../../Components/Messages/FetchLoading';
import { MessageGenerator, msg, type } from '../../../Components/Messages/MesageGenerator';
import { CircularProgress, IconButton } from '@mui/material';
import { CloseOutlined } from '@mui/icons-material';

export default function CreateManager({open, closer, updater}) {
//   const [open, setOpen] = useState(false);
    const [err, setErr] = useState({error: false, message: ""})
    const [msg, setMsg] = useState({suc: false, message: ""})
    const [mutate, { isLoading, isSuccess,isError, error }] = usePostManagerMutation();
    const regId = useSelector(state => state.user.regionId)
    const tenantId = useSelector(state => state.user.tenantId)
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
  });
  const handleChange =async (event) => {
    setErr((prev) => {return {...prev, error: false, message:""}})
    setMsg((prev) => {return {suc: false, message: "" }})
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };



  const handleSubmit = async (event) => {
    event.preventDefault();
    const { firstName, lastName, email, password } = formData;
    if (!firstName || !lastName || !email || !password) {
      setErr((prev) => { return {...prev, error: true, message: 'Please fill in all required fields.'}})
    }
    else {
        const response = await mutate({formData: formData})
        if(response.data) {
          setErr((prev) => {return {...prev, error: false, message:""}})
          setMsg((prev) => {return {suc:true, message: response.data.data.message }})
          setFormData((prev) =>{ return {
            firstName: '',
            lastName: '',
            email: '',
            password: '',
                  }})
          updater()
        }
        if(response.error) {
          setMsg((prev) => {return {suc:false, message: "" }})
          setErr((prev) => {return {...prev, error: true, message: response.error.data.data.message}})
        }
    }
  };

  return (
    <>
      <Dialog open={open} PaperProps={{ component: 'form' }}>
        <div style={{ padding:"1%" }}>
        <div style={{ width: "100%", display: "flex", justifyContent: "right" }}>
        <div style={{ width:"90%" }}>
        <DialogTitle>Add Manager</DialogTitle>
        </div>
        <div>
          <IconButton color='error' >
            <CloseOutlined style={{ margin: "5px" }} onClick={()=>{closer((prev)=>{return false})}} />
          </IconButton>
        </div>
        </div>
        
        <DialogContent>
          <DialogContentText>
            To Add Manager please fill the following information.
          </DialogContentText>
          <TextField
            autoFocus
            required
            margin="dense"
            id="firstName"
            name="firstName"
            label="First Name"
            type="text"
            fullWidth
            variant="standard"
            onChange={handleChange}
            value={formData.firstName}
          />
          <TextField
            required
            margin="dense"
            id="lastName"
            name="lastName"
            label="Last Name"
            type="text"
            fullWidth
            variant="standard"
            onChange={handleChange}
            value={formData.lastName}
          />
          <TextField
            required
            margin="dense"
            id="email"
            name="email"
            label="Email Address"
            type="email"
            fullWidth
            variant="standard"
            onChange={handleChange}
            value={formData.email}
          />
          <TextField
            required
            margin="dense"
            id="password"
            name="password"
            label="Password"
            type="password"
            fullWidth
            variant="standard"
            onChange={handleChange}
            value={formData.password}
          />
        </DialogContent>
        <div style={{ display: "flex", justifyContent:"center" }}>
        {isLoading && <CircularProgress style={{ margin:"auto 0" }} />}
        {(msg.suc && !isLoading)  && <MessageGenerator type={type.suc} message={msg.message} />}
        {(err.error && !isLoading) && <MessageGenerator type={type.err} message={err.message} />}
        </div>
        <DialogActions>
          <Button onClick={()=>{closer((prev)=>{return false})}}>Cancel</Button>
          <Button type="submit" variant='contained' onClick={handleSubmit}>
            Create Manager
          </Button>
        </DialogActions>
        </div>
      </Dialog>
    </>
  );
}
