import { createSlice } from "@reduxjs/toolkit";

const tempIDSlice = createSlice({
    name:"tempId",
    initialState: [],
    reducers: {
        addTempId:(state, action) => { return {id: action.payload}},
        removeTempId:(state) => { return {}}
    }
})


export default tempIDSlice.reducer
export const {addTempId, removeTempId} = tempIDSlice.actions;