// import { useDispatch } from "react-redux";
// import FetchLoading from "../../../Components/Messages/FetchLoading";
// import MainSideBar from "../../../Components/SideBar";
// import { apiSlice, useGetAllTenantsQuery, useGetFormsListQuery, useGetManagersQuery } from "../../../Services/ApiServices";
// import { useEffect, useState } from "react";
// // import { addManagers } from "../../../App/features/managerSlice";
// import DataGridTable from "../../../Components/Datagrid";
// import { Button } from "@mui/material";
// import { Add, Delete, Edit } from "@mui/icons-material";
// // import DeleteTenant from "./DeleteTenant";
// // import EditTenant from "./EditTenant";
// import { addTenants } from "../../../App/features/tenantsSlice";
// import { addFormsList } from "../../../App/features/formsListSlice";
// import CreateForm from "./CreateForm";
// import EditFormsList from "./EditFormsList";
// import DeleteFormsList from "./DeleteFormsList";

// function getFullDate(dateTimeString) {
//   try {
//     const dateObj = new Date(dateTimeString);
//     return dateObj.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
//   } catch (error) {
//     console.error('Error parsing ISO 8601 date string:', error.message);
//     return ''; 
//   }
// }

// export default function ViewFormList () {
//     const [tempList, setTempList] = useState([])
//     const [open, setOpen] = useState(false);
//     const [openEdit, setOpenEdit] = useState(false);
//     const [editData, setEditData] = useState({});
//     const [manId, setId] = useState("");
//     const [openDelete, setOpenDelete] = useState(false);
//     const [name, setName] = useState("");
//     const { data, refetch, isLoading, error } = useGetFormsListQuery()
//     const dispatch = useDispatch()
//     const [err, setErr] = useState({error: false, message: ""})
//     const [loading, setLoading] = useState(true)
//      const updater = async () =>{
//       dispatch(apiSlice.util.resetApiState())
//       const res = await refetch({ forceRefetch: true }).then((response)=>{
//       if(response.isError) {
//         setLoading((prev) =>{ return false})
//         setErr((prev) => { return {...prev, error: true, message: response}})
//       }
//       if(response.isSuccess) {
//         console.log(response.data.data.review_forms)
//         dispatch(addFormsList(response.data.data.review_forms))
//         setTempList((prev) =>{
//           return response.data.data.review_forms.map((item)=>{
//               return {
//             id: item.id,
//             title: item.title,
//             price: item.price,
//             created: getFullDate(item.createdAt),
//             updated: getFullDate(item.updatedAt),
//             actions: item
//         }
//           })
//         })
//         setLoading((prev) =>{ return false})
//       }
//     })
//   }
  
//   const columns = [
//   {
//     field: 'title',
//     headerName: 'Title',
//     width: 350,
//     editable: false,
//   },
//   {
//     field: 'price',
//     headerName: 'Price',
//     width: 200,
//     editable: false,
//   },
//   {
//     field: 'created',
//     headerName: 'Created',
//     width: 180,
//     editable: false,
//   },
//   {
//     field: 'updated',
//     headerName: 'Updated',
//     width: 180,
//     editable: false,
//   },
//   {
//     field: 'actions',
//     headerName: 'Actions',
//     width: 180,
//     editable: false,
//     renderCell: (params) => {
//             return (
//                 <>
//                     <Button color='success' onClick={(e)=>
//                     {
//                       setEditData((prev)=>{return params.formattedValue});
//                       setOpenEdit((prev)=>{return true});
//                     }}><Edit/></Button>
//                     <Button color='error' onClick={(e)=>{
//                       let tempName = `${params.formattedValue.title}`
//                       let tempId = params.formattedValue.id
//                       setId((prev) => {return tempId})
//                       setName((prev) => {return tempName})
//                       setOpenDelete((prev) => {return true})
//                     }}><Delete color = "error" /></Button>
//                 </>
//             )}
//           },

//         ];


//     useEffect(()=>{
//       updater()
//     },[])
//     const addbtn = <Button style={{ margin:"2%" }} onClick={()=>{setOpen((prev)=>{return true})}} variant='outlined' color="success"><Add/> Add Form</Button>
//     return (
//         <MainSideBar>
//             <CreateForm open={open} closer={setOpen} updater = {updater}/>
//             <EditFormsList open={openEdit} admin={editData} closer={setOpenEdit} updater = {updater}/>
//             <DeleteFormsList handleClose={setOpenDelete} manId={manId} updater = {updater} name={name} open={openDelete} />
//             {loading ? <FetchLoading/> : <DataGridTable  title={"Review Forms"} options={addbtn} columns={columns} data={tempList} />}
//         </MainSideBar>
//     )

// }


import { useDispatch } from "react-redux";
import FetchLoading from "../../../Components/Messages/FetchLoading";
import MainSideBar from "../../../Components/SideBar";
import { apiSlice, useGetAllTenantsQuery, useGetFormsListQuery, useGetManagersQuery } from "../../../Services/ApiServices";
import { useEffect, useState } from "react";
import DataGridTable from "../../../Components/Datagrid";
import { Button } from "@mui/material";
import { Add, Delete, Edit } from "@mui/icons-material";
import { addTenants } from "../../../App/features/tenantsSlice";
import { addFormsList } from "../../../App/features/formsListSlice";
import CreateForm from "./CreateForm";
import EditFormsList from "./EditFormsList";
import DeleteFormsList from "./DeleteFormsList";
import { addTempId } from "../../../App/features/tempFormID";
import { useNavigate } from "react-router-dom";

function getFullDate(dateTimeString) {
  try {
    const dateObj = new Date(dateTimeString);
    return dateObj.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
  } catch (error) {
    console.error('Error parsing ISO 8601 date string:', error.message);
    return ''; 
  }
}

export default function ViewFormList () {
    const nav = useNavigate()
    const [tempList, setTempList] = useState([])
    const [open, setOpen] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [editData, setEditData] = useState({});
    const [manId, setId] = useState("");
    const [openDelete, setOpenDelete] = useState(false);
    const [name, setName] = useState("");
    const { data, refetch, isLoading, error } = useGetFormsListQuery()
    const dispatch = useDispatch()
    const [err, setErr] = useState({error: false, message: ""})
    const [loading, setLoading] = useState(true)
     const updater = async () =>{
      dispatch(apiSlice.util.resetApiState())
      const res = await refetch({ forceRefetch: true }).then((response)=>{
      if(response.isError) {
        setLoading((prev) =>{ return false})
        setErr((prev) => { return {...prev, error: true, message: response}})
      }
      if (response.isSuccess) {
        console.log(response.data.data.review_forms);
        dispatch(addFormsList(response.data.data.review_forms));
        setTempList(response.data.data.review_forms.map((item) => {
          return {
            id: item.id,
            title: item.title || 'No Title', // Provide a fallback value
            price: item.price || 'No Price', // Provide a fallback value
            created: getFullDate(item.createdAt),
            updated: getFullDate(item.updatedAt),
            actions: item
          };
        }));
        setLoading(false);
      }
    });
  };

  const columns = [
  {
    field: 'title',
    headerName: 'Title',
    width: 200,
    editable: false,
  },
  {
    field: 'price',
    headerName: 'Price',
    width: 200,
    editable: false,
  },
  {
    field: 'created',
    headerName: 'Created',
    width: 180,
    editable: false,
  },
  {
    field: 'updated',
    headerName: 'Updated',
    width: 180,
    editable: false,
  },
  {
    field: 'actions',
    headerName: 'Actions',
    width: 300,
    editable: false,
    renderCell: (params) => {
            return (
                <>
                    <Button color='success' onClick={(e)=>
                    {
                      setEditData((prev)=>{return params.formattedValue});
                      setOpenEdit((prev)=>{return true});
                    }}><Edit/></Button>
                    <Button color='error' onClick={(e)=>{
                      let tempName = `${params.formattedValue.title}`
                      let tempId = params.formattedValue.id
                      setId((prev) => {return tempId})
                      setName((prev) => {return tempName})
                      setOpenDelete((prev) => {return true})
                    }}><Delete color = "error" /></Button>
                    <Button onClick={() =>{
                      
                      nav(`/viewforms/${params.formattedValue.id}`)
                    }}>Customize</Button>
                </>
            )}
          },

        ];

  useEffect(() => {
    updater();
  }, []);

  const addbtn = <Button style={{ margin: "2%" }} onClick={() => setOpen(true)} variant='outlined' color="success"><Add /> Add Form</Button>;

  return (
    <MainSideBar>
      <CreateForm open={open} closer={setOpen} updater={updater} />
      <EditFormsList open={openEdit} admin={editData} closer={setOpenEdit} updater={updater} />
      <DeleteFormsList handleClose={setOpenDelete} manId={manId} updater={updater} name={name} open={openDelete} />
      {loading ? <FetchLoading /> : <DataGridTable title={"Review Forms"} options={addbtn} columns={columns} data={tempList} />}
    </MainSideBar>
  );
}



// title: item.title || 'No Title', // Provide a fallback value
// price: item.price || 'No Price', // Provide a fallback value