import {TextField,Checkbox, Button,Typography,Stack,Chip, Select, MenuItem, Paper, InputLabel,FormControl, FormControlLabel, Grid, Rating, FormGroup, Box, Divider,    } from '@mui/material/';
import { UploadFile } from '@mui/icons-material';

function capitalize(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

const ClientCreateElement = (data,setFormData, theme, userInput, setUserInput) => {

    const newItem =  {}
switch (data.inputType) {
    case 'CheckBoxes':
        return <Grid container style={{ marginTop:"10px" }} alignItems="center">
      <Grid item xs={12}>
        <label color={theme.palette.text.main} name={data.title} htmlFor="chk">
          {data.title}
        </label>
      </Grid>
      <Grid item xs={12}>
        <FormGroup>
          <Box display="flex" flexWrap="wrap">
            {data.options.split(",").map((item, index) => (
              console.log(item),
              <FormControlLabel
                key={item}
                control={<Checkbox id={`${data.id}_${index}`} checked={item.value}
                onChange={(e) => {
                  setFormData(prevState =>
                    prevState.map(stateItem =>
                      stateItem.id === data.id ? { ...stateItem, value: function() {if(stateItem.value) { return `${stateItem.value},${item}`} else {return `${item}` }}()  } : stateItem
                    )
                  );
                }} />}
                value={item}
                label={item}
                sx={{ mr: 1 }} // Add margin for spacing between checkboxes
              />
            ))}
          </Box>
        </FormGroup>
      </Grid>
    </Grid>
        break;
    case 'DropDown':
        return <Grid align="center" style={{ marginTop:"10px" }}  xs={12}>
            <FormControl fullWidth required>
            <InputLabel  htmlFor="input-type-label">{data.title}</InputLabel>
               <Select
               id={data.id}
               name={data.title}
               required = {data.required} 
               label={data.title}
               value={data.value}
              onChange={(e)=>{
                setFormData(prevState =>
                  prevState.map(item =>
                    item.id === data.id ? { ...item, value: e.target.value } : item
                  )
                );
              }}
              size='small'
              labelId="input-type-label">
                {
                    data.options.split(",").map((item) => {
                        return <MenuItem key={item} name={data.title}  value={item}>
                        {item}
                        </MenuItem>
                    })
                }
            </Select>
            </FormControl>
            </Grid>
        break;
    case 'Files':
        return <Grid align="center" style={{ marginTop:"10px" }}  xs={12}>
                        <TextField
                        sx={{ display: 'none' }}
                id={data.id}
                type="file"
                name={data.title} 
                required = {data.required} 
              />
              <label htmlFor="file-upload">
                <Button variant="contained" component="span" fullWidth startIcon={<UploadFile />}>
                 {data.title}
                </Button>
              </label>
            </Grid>
        break;
    case 'paragraph':
        return <Grid align="center"  style={{ marginTop:"10px" }} xs={12}>
               <TextField type='text' label={capitalize(data.title)}
               size='small'
               multiline
                name={data.title}
                id={data.id}
               rows={4}
               value={data.value}
              onChange={(e)=>{
                setFormData(prevState =>
                  prevState.map(item =>
                    item.id === data.id ? { ...item, value: e.target.value } : item
                  )
                );
              }}
               required = {data.required} 
               fullWidth
               maxRows={7}/>
            </Grid>
        break;
    case 'Sentence':
        return <Grid align="center" style={{ marginTop:"10px" }}  xs={12}>
               <TextField type='text' label={capitalize(data.title)}
               required = {data.required} 
                name={data.title}
               size='small'
               id={data.id}
               value={data.value}
                onChange={(e)=>{
                  setFormData(prevState =>
                    prevState.map(item =>
                      item.id === data.id ? { ...item, value: e.target.value } : item
                    )
                  );
                }}
               placeholder={capitalize(data.title)} fullWidth/>
            </Grid>
        break;
    case 'Star Rating':
        // console.log("Itemmmmmmmmmmmmmmm", data);
        return <Grid align="left" style={{ marginTop:"10px" }}  xs={12}>
              <Typography color={theme.palette.text.main} component="legend">{data.title}</Typography>
              <Rating
              id={data.id}
              required = {data.required}
              value={data.value}
              onChange={(e)=>{
                setFormData(prevState =>
                  prevState.map(item =>
                    item.id === data.id ? { ...item, value: e.target.value } : item
                  )
                );
              }}
              name={data.title}
              />
            </Grid>
        break;

    default:
        return ""
        break;
}

}

export default ClientCreateElement