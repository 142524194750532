import { createSlice } from "@reduxjs/toolkit";

const adIdSlice = createSlice({
    name:"adId",
    initialState: [],
    reducers: {
        addAdindex:(state, action) => { return {id:action.payload}},
    }
})


export default adIdSlice.reducer
export const { addAdindex } = adIdSlice.actions;