import {createSlice} from "@reduxjs/toolkit"

const initial_state = {
    ligtTheme: true 
}

const preferenceSlice = createSlice({
    name: "preference",
    initialState: initial_state,
    reducers: {
        darkOn: (state) =>{
            return {...state, ligtTheme: false}
        },
        lightOn: (state) =>{
            return {...state, ligtTheme: true}
        }
    }
})

export default preferenceSlice.reducer
export const {darkOn, lightOn} = preferenceSlice.actions