import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useDispatch, useSelector } from 'react-redux';
import { apiSlice, useAddNewFormMutation, useGetTenantTypeQuery, usePostAdminMutation, usePostManagerMutation, usePostTenantMutation } from '../../../Services/ApiServices';
import FetchLoading from '../../../Components/Messages/FetchLoading';
import { MessageGenerator, msg, type } from '../../../Components/Messages/MesageGenerator';
import { CircularProgress, FormControl, IconButton, InputLabel, MenuItem, Select } from '@mui/material';
import { CloseOutlined } from '@mui/icons-material';

export default function CreateForm({open, closer, updater}) {

    const dispatch = useDispatch()
    const [err, setErr] = useState({error: false, message: ""})
    const [msg, setMsg] = useState({suc: false, message: ""})
    const [mutate, { isLoading, isSuccess,isError, error }] = useAddNewFormMutation()
    const [loading, setLoading] = useState(true)
    const [tempList, setTempList] = useState([])
    const [formData, setFormData] = useState({
      title: '',
      price: '',
    });
  const handleChange =async (event) => {
    setErr((prev) => {return {...prev, error: false, message:""}})
    setMsg((prev) => {return {suc: false, message: "" }})
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const { title, price } = formData;
    if (!title || !price) {
      setErr((prev) => { return {...prev, error: true, message: 'Please fill in all required fields.'}})
    }
    else {
      console.log("Form Data", formData);
        const response = await mutate({...formData})
        console.log("respos ", response);
        
        if(response.data) {
          setErr((prev) => {return {...prev, error: false, message:""}})
          setMsg((prev) => {return {suc:true, message: response.data.data.message }})
          setFormData((prev) =>{ return {
                  title: '',
                  price: '',
          }})
          updater()
        }
        if(response.error) {
          setMsg((prev) => {return {suc:false, message: "" }})
          setErr((prev) => {return {...prev, error: true, message: response.error.data.data.message}})
        }
    }
  };

  return (
    <>
      <Dialog open={open} PaperProps={{ component: 'form' }}>
        <div style={{ padding:"1%" }}>
        <div style={{ width: "100%", display: "flex", justifyContent: "right" }}>
        <div style={{ width:"90%" }}>
        <DialogTitle>Add Form</DialogTitle>
        </div>
        <div>
          <IconButton color='error' >
            <CloseOutlined style={{ margin: "5px" }} onClick={()=>{closer((prev)=>{return false})}} />
          </IconButton>
        </div>
        </div>
        
        <DialogContent>
          <DialogContentText>
            To Add Form please fill the following information.
          </DialogContentText>
          <TextField
            autoFocus
            required
            margin="dense"
            id="title"
            name="title"
            label="Title"
            type="text"
            fullWidth
            variant="standard"
            onChange={handleChange}
            value={formData.title}
          />
          <TextField
            required
            margin="dense"
            id="price"
            name="price"
            label="Price"
            type="number"
            fullWidth
            variant="standard"
            onChange={handleChange}
            value={formData.price}
          />
        </DialogContent>
        <div style={{ display: "flex", justifyContent:"center" }}>
        {isLoading && <CircularProgress style={{ margin:"auto 0" }} />}
        {(msg.suc && !isLoading)  && <MessageGenerator type={type.suc} message={msg.message} />}
        {(err.error && !isLoading) && <MessageGenerator type={type.err} message={err.message} />}
        </div>
        <DialogActions>
          <Button onClick={()=>{closer((prev)=>{return false})}}>Cancel</Button>
          <Button type="submit" variant='contained' onClick={handleSubmit}>
            Create Form
          </Button>
        </DialogActions>
        </div>
      </Dialog>
    </>
  );
}
