import { createSlice } from "@reduxjs/toolkit";


const initialState = [];

const formSlice = createSlice({
    name: "form",
    initialState: initialState,
    reducers: {
        addToForm: (state, action) => {
            return [...action.payload];
        },
        removeForm: (state) => {
            return [];
        }
    }
});

export default formSlice.reducer;
export const { addToForm, removeForm } = formSlice.actions;
