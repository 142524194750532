import { useDispatch, useSelector } from "react-redux";
import MainSideBar from "../../Components/SideBar";
import { useTheme } from "@emotion/react";
import { useNavigate } from "react-router-dom";
import { apiSlice, useGetDashboardsQuery } from "../../Services/ApiServices";
import { addAdindex } from "../../App/features/adID";
import { addAdRegions } from "../../App/features/adRegionSlice";
import { useEffect, useState } from "react";
import RegionCard from "../../Components/RegionCard";
import { Grid, Typography } from "@mui/material";
import FetchLoading from "../../Components/Messages/FetchLoading";
import NoData from "../../Components/NoData";

export default function AdminHome () {
    const dispatch = useDispatch()
    const regions = useSelector(state=> state.adRegion)
    const name = useSelector(state=> state.user.firstName)
    const theme = useTheme()
    const nav = useNavigate()
    
    const [err, setErr] = useState({error: false, message: ""})
        const [loading, setLoading] = useState(true)
        const { data, refetch, isLoading, error } = useGetDashboardsQuery();
        const updater = async () =>{
        dispatch(apiSlice.util.resetApiState())
        const res = await refetch({ forceRefetch: true }).then((response)=>{
            setLoading((prev) =>{ return false})
        if(response.isError) {
            setErr((prev) => { return {...prev, error: true, message: response}})
        }
        if(response.isSuccess) {
            console.log("The Data accepted", response.data.data.regions);
            dispatch(addAdRegions(response.data.data.regions))
        }
        })
    }
    const handler = (id, index) =>{
        dispatch(addAdindex(id))
        nav(`/adBranches/${index}`)
    }
    useEffect(()=>{
        updater()
    },[])
    return(
        <MainSideBar>
            {
                loading ? <FetchLoading/> :
                <>
                {(regions.length > 0) ? <Grid container spacing={2}>
                {
                    regions.map((item, index) => {
                    return <RegionCard regionName={item.name} id={item.id} />
                    })
                }
                </Grid> :
                <NoData message={"No Regions Yet."} />   
                }
                </>
            }
        </MainSideBar>
    )
}