import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

const handleExportPDF = async (id) => {
  const input = document.getElementById(id);
  const canvas = await html2canvas(input);
  const imgData = canvas.toDataURL('image/png');
  const pdf = new jsPDF();
  pdf.addImage(imgData, 'PNG', 0, 0);
  pdf.setProperties({
    title: 'My Downloaded PDF',
  });
  pdf.save('my-export.pdf');
};

export default handleExportPDF