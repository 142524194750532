import { createSlice } from "@reduxjs/toolkit";

const formsListSlice = createSlice({
    name:"formsList",
    initialState: [],
    reducers: {
        addFormsList:(state, action) => { return { ...action.payload }},
        removeFormsList:(state) => { return {}}
    }
})


export default formsListSlice.reducer
export const {addFormsList, removeFormsList} = formsListSlice.actions;