import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useSelector } from 'react-redux';
import { useAddTenantTypeMutation, usePostAdminMutation, usePostManagerMutation } from '../../../Services/ApiServices';
import FetchLoading from '../../../Components/Messages/FetchLoading';
import { MessageGenerator, msg, type } from '../../../Components/Messages/MesageGenerator';
import { CircularProgress, IconButton } from '@mui/material';
import { CloseOutlined } from '@mui/icons-material';

export default function CreateTenantType({open, closer, updater}) {
//   const [open, setOpen] = useState(false);
    const [err, setErr] = useState({error: false, message: ""})
    const [msg, setMsg] = useState({suc: false, message: ""})
    const [mutate, { isLoading, isSuccess,isError, error }] = useAddTenantTypeMutation();
  const [formData, setFormData] = useState({
    title: '',
  });
  const handleChange =async (event) => {
    setErr((prev) => {return {...prev, error: false, message:""}})
    setMsg((prev) => {return {suc: false, message: "" }})
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };



  const handleSubmit = async (event) => {
    event.preventDefault();
    const { title } = formData;
    if (!title) {
      setErr((prev) => { return {...prev, error: true, message: 'Please fill in all required fields.'}})
    }
    else {
        const response = await mutate({title: formData.title})
        if(response.data) {
          setErr((prev) => {return {...prev, error: false, message:""}})
          setMsg((prev) => {return {suc:true, message: response.data.data.message }})
          setFormData((prev) =>{ return {
            title:""
                  }})
          updater()
        }
        if(response.error) {
          setMsg((prev) => {return {suc:false, message: "" }})
          setErr((prev) => {return {...prev, error: true, message: response.error.data.data.message}})
        }
    }
  };

  return (
    <>
      <Dialog open={open} PaperProps={{ component: 'form' }}>
        <div style={{ padding:"1%" }}>
        <div style={{ width: "100%", display: "flex", justifyContent: "right" }}>
        <div style={{ width:"90%" }}>
        <DialogTitle>Add Type</DialogTitle>
        </div>
        <div>
          <IconButton color='error' >
            <CloseOutlined style={{ margin: "5px" }} onClick={()=>{closer((prev)=>{return false})}} />
          </IconButton>
        </div>
        </div>
        
        <DialogContent>
          <DialogContentText>
            To Add Tenant Type please fill the following information.
          </DialogContentText>
          <TextField
            autoFocus
            required
            margin="dense"
            id="title"
            name="title"
            label="Tenant Type"
            type="text"
            fullWidth
            variant="standard"
            onChange={handleChange}
            value={formData.title}
          />
        </DialogContent>
        <div style={{ display: "flex", justifyContent:"center" }}>
        {isLoading && <CircularProgress style={{ margin:"auto 0" }} />}
        {(msg.suc && !isLoading)  && <MessageGenerator type={type.suc} message={msg.message} />}
        {(err.error && !isLoading) && <MessageGenerator type={type.err} message={err.message} />}
        </div>
        <DialogActions>
          <Button onClick={()=>{closer((prev)=>{return false})}}>Cancel</Button>
          <Button type="submit" variant='contained' onClick={handleSubmit}>
            Create Type
          </Button>
        </DialogActions>
        </div>
      </Dialog>
    </>
  );
}
