import React, { useEffect, useRef, useState } from 'react';
import Image from "../Assets/nexus light .png"
import QRCodeStyling, {
  DrawType,
  TypeNumber,
  Mode,
  ErrorCorrectionLevel,
  DotType,
  CornerSquareType,
  CornerDotType,
  Extension,
  Options,
} from 'qr-code-styling';

export default function QrCodeRenderer({ data = 'http://qr-code-styling.com' }) {
  const [options, setOptions] = useState({
    width: 250,
    height: 250,
    type: 'svg',
    data,
    image: Image,
    margin: 10,
    qrOptions: {
      typeNumber: 0,
      mode: 'Byte',
      errorCorrectionLevel: 'Q',
    },
    imageOptions: {
      hideBackgroundDots: true,
      imageSize: 0.5,
      margin: 5,
      crossOrigin: 'anonymous',
    },
    dotsOptions: {
      color: '#186F65',
      type: 'rounded',
    },
    backgroundOptions: {
      color: '#ffffff',
    },
    cornersSquareOptions: {
      color: '#186F65',
      type: 'extra-rounded',
    },
    cornersDotOptions: {
      color: '#186F65',
      type: 'dot',
    },
  });
  const [qrCode] = useState(new QRCodeStyling(options));
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current) {
      qrCode.append(ref.current);
    }
  }, [qrCode, ref]);

  useEffect(() => {
    setOptions((prevOptions) => ({ ...prevOptions, data })); // Update data in options
    if (!qrCode) return;
    qrCode.update(options);
  }, [data, qrCode]);

  return (
    <div className="App">
      <div ref={ref} />
    </div>
  );
}
